import React, { useState, useEffect } from "react";
import {
  FileText,
  Flag,
  Trophy,
  ArrowRight,
  Star,
  Award,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";

const AchievementsSection = () => {
  const [activeTab, setActiveTab] = useState("research");
  const [currentSlide, setCurrentSlide] = useState(0);

  const achievements = {
    research: [
      {
        icon: FileText,
        title: "A case study of Pakistan to bridge the gap",
        description:
          "A survey of cybersecurity laws, regulations, and policies in technologically advanced nations ",
        stats: [
          { icon: Star, value: "2023", label: "Published" },
          { icon: Trophy, value: "50+", label: "Citations" },
        ],
      },
      {
        icon: FileText,
        title: "Survey of Bug Bounty Program",
        description:
          "A Survey of Bug Bounty Programs in Strengthening Cybersecurity and Privacy in the Blockchain Industry",
        stats: [
          { icon: Star, value: "4.9", label: "Impact Factor" },
          { icon: Trophy, value: "3", label: "Collaborators" },
        ],
      },
      {
        icon: FileText,
        title: "DelSec",
        description:
          "An Anti-Forensics Data Deletion Framework for Smartphones, IoT, and Edge Devices",
        stats: [
          { icon: Star, value: "2022", label: "Published" },
          { icon: Trophy, value: "30+", label: "Citations" },
        ],
      },
      {
        icon: Award,
        title: "IoT Security Architecture",
        description:
          "Comprehensive security architecture for Internet of Things devices.",
        stats: [
          { icon: Star, value: "4.7", label: "Impact Factor" },
          { icon: Trophy, value: "5", label: "Collaborators" },
        ],
      },
      {
        icon: FileText,
        title: "ZSmart Cities",
        description:
          "A Novel Framework for Energy Production and Harvesting using Renewable Energy",
        stats: [
          { icon: Star, value: "2021", label: "Published" },
          { icon: Trophy, value: "40+", label: "Citations" },
        ],
      },
      {
        icon: Award,
        title: "Machine Learning for Intrusion Detection",
        description: "Advanced ML techniques for detecting network intrusions.",
        stats: [
          { icon: Star, value: "4.8", label: "Impact Factor" },
          { icon: Trophy, value: "4", label: "Collaborators" },
        ],
      },
    ],
    ctf: [
      {
        icon: Flag,
        title: "Blackhat Mea 2024 ",
        description: "6th place in Pakistan, 2nd in Air University Islamabad",
        stats: [
          { icon: Trophy, value: "1st", label: "Place" },
          { icon: Star, value: "15k", label: "Points" },
        ],
      },
      {
        icon: Award,
        title: "Ignite hackathon 2023",
        description: "3rd position in red team, and 5 position in overall competition",
        stats: [
          { icon: Trophy, value: "50+", label: "Machines" },
          { icon: Star, value: "Elite", label: "Rank" },
        ],
      },
      {
        icon: Flag,
        title: "BSides Fargo 2.0 USA ",
        description: "4th position in the BSides Fargo 2.0 Hackathon",
        stats: [
          { icon: Trophy, value: "2", label: "Successful Exploits" },
          { icon: Star, value: "$50k", label: "Prize Money" },
        ],
      },
     
      
    ],
  };

  const totalSlides = Math.ceil(achievements[activeTab].length / 3);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + totalSlides) % totalSlides);
  };

  useEffect(() => {
    setCurrentSlide(0);
  }, [activeTab]);

  const visibleAchievements = achievements[activeTab].slice(
    currentSlide * 3,
    (currentSlide + 1) * 3
  );

  return (
    <div className="achievements-section">
      <div className="achievements-container">
        <h2 className="section-title">Notable Achievements</h2>
        <p className="section-subtitle">
          Explore our groundbreaking research and competitive victories
        </p>

        <div className="tab-container">
          <button
            className={`tab-button ${activeTab === "research" ? "active" : ""}`}
            onClick={() => setActiveTab("research")}
          >
            <FileText className="tab-icon" />
            Research Papers
          </button>
          <button
            className={`tab-button ${activeTab === "ctf" ? "active" : ""}`}
            onClick={() => setActiveTab("ctf")}
          >
            <Flag className="tab-icon" />
            CTF Achievements
          </button>
        </div>

        <div className="achievements-slider">
          <button onClick={prevSlide} className="slider-btn prev-btn">
            <ChevronLeft size={24} />
          </button>
          <div className="achievements-grid">
            {visibleAchievements.map((achievement, index) => (
              <div key={index} className="achievement-card">
                <div className="achievement-icon">
                  <achievement.icon size={24} />
                </div>
                <h3 className="achievement-title">{achievement.title}</h3>
                <p className="achievement-description">
                  {achievement.description}
                </p>
                <div className="achievement-stats">
                  {achievement.stats.map((stat, statIndex) => (
                    <div key={statIndex} className="stat">
                      <stat.icon size={16} className="stat-icon" />
                      <span className="stat-value">{stat.value}</span>
                      <span className="stat-label">{stat.label}</span>
                    </div>
                  ))}
                </div>
                <button className="learn-more-btn">
                  Learn more <ArrowRight size={16} />
                </button>
              </div>
            ))}
          </div>
          <button onClick={nextSlide} className="slider-btn next-btn">
            <ChevronRight size={24} />
          </button>
        </div>

        <div className="slider-indicators">
          {[...Array(totalSlides)].map((_, index) => (
            <span
              key={index}
              className={`indicator ${index === currentSlide ? "active" : ""}`}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </div>
      </div>

      <style jsx>{`
        .achievements-section {
          background-color: #111111;
          min-height: 100vh;
          padding: 5rem 0;
          position: relative;
          overflow: hidden;
        }

        .achievements-container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 2rem;
          position: relative;
          z-index: 10;
        }

        .section-title {
          font-size: 3.5rem;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          margin-bottom: 1rem;
          text-shadow: 0 0 10px rgba(255, 87, 34, 0.3);
        }

        .section-subtitle {
          font-size: 1.2rem;
          color: #cccccc;
          text-align: center;
          max-width: 600px;
          margin: 0 auto 3rem;
        }

        .tab-container {
          display: flex;
          justify-content: center;
          margin-bottom: 3rem;
        }

        .tab-button {
          display: flex;
          align-items: center;
          padding: 0.75rem 1.5rem;
          border: none;
          background-color: #222222;
          color: #ffffff;
          font-size: 1rem;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.3s ease;
          margin: 0 0.5rem;
          border-radius: 9999px;
        }

        .tab-button:hover,
        .tab-button.active {
          background-color: #ff5722;
          transform: translateY(-2px);
        }

        .tab-icon {
          margin-right: 0.5rem;
        }

        .achievements-slider {
          position: relative;
          display: flex;
          align-items: center;
        }

        .achievements-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 2rem;
          transition: transform 0.3s ease;
        }

        .achievement-card {
          background: #1a1a1a;
          border-radius: 1rem;
          padding: 2rem;
          transition: all 0.3s ease;
          display: flex;
          flex-direction: column;
        }

        .achievement-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 10px 20px rgba(255, 87, 34, 0.1);
        }

        .achievement-icon {
          background-color: rgba(255, 87, 34, 0.1);
          color: #ff5722;
          padding: 1rem;
          border-radius: 0.75rem;
          display: inline-block;
          margin-bottom: 1rem;
        }

        .achievement-title {
          font-size: 1.5rem;
          font-weight: bold;
          color: #ffffff;
          margin-bottom: 1rem;
        }

        .achievement-description {
          color: #cccccc;
          margin-bottom: 1.5rem;
          font-size: 1rem;
          line-height: 1.5;
          flex-grow: 1;
        }

        .achievement-stats {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 1rem;
          margin-bottom: 1.5rem;
        }

        .stat {
          background-color: #222222;
          padding: 0.75rem;
          border-radius: 0.5rem;
          text-align: center;
        }

        .stat-icon {
          color: #ff5722;
          margin-bottom: 0.25rem;
        }

        .stat-value {
          font-size: 1.25rem;
          font-weight: bold;
          color: #ffffff;
          display: block;
        }

        .stat-label {
          font-size: 0.875rem;
          color: #cccccc;
        }

        .learn-more-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #222222;
          color: #ff5722;
          border: none;
          padding: 0.75rem 1rem;
          border-radius: 0.5rem;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.3s ease;
          width: 100%;
        }

        .learn-more-btn:hover {
          background-color: #ff5722;
          color: #ffffff;
        }

        .learn-more-btn svg {
          margin-left: 0.5rem;
          transition: transform 0.3s ease;
        }

        .learn-more-btn:hover svg {
          transform: translateX(5px);
        }

        .slider-btn {
          background-color: rgba(34, 34, 34, 0.8);
          color: #ffffff;
          border: none;
          padding: 1rem;
          border-radius: 50%;
          cursor: pointer;
          transition: all 0.3s ease;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 1;
        }

        .slider-btn:hover {
          background-color: #ff5722;
        }

        .prev-btn {
          left: -1.5rem;
        }

        .next-btn {
          right: -1.5rem;
        }

        .slider-indicators {
          display: flex;
          justify-content: center;
          margin-top: 2rem;
        }

        .indicator {
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 50%;
          background-color: #222222;
          margin: 0 0.5rem;
          cursor: pointer;
          transition: all 0.3s ease;
        }

        .indicator.active {
          background-color: #ff5722;
          transform: scale(1.2);
        }

        @media (max-width: 1024px) {
          .achievements-grid {
            grid-template-columns: repeat(2, 1fr);
          }
        }

        @media (max-width: 768px) {
          .section-title {
            font-size: 2.5rem;
          }

          .achievements-grid {
            grid-template-columns: 1fr;
          }

          .slider-btn {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default AchievementsSection;
