import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import aboutImg from "../assets/homepage/home1.jpeg";
import { FiCheckCircle, MdKeyboardArrowRight } from '../assets/icons/vander';

export default function AboutOne() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    }
                });
            },
            { threshold: 0.2 }
        );

        const element = document.querySelector('.about-container');
        if (element) {
            observer.observe(element);
        }

        return () => {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, []);

    return (
        <>
            <style>
                {`
                    .text-darkOrange {
                        color: #ff6600 !important;
                    }
                    
                    @keyframes slideFromLeft {
                        from {
                            opacity: 0;
                            transform: translateX(-100px);
                        }
                        to {
                            opacity: 1;
                            transform: translateX(0);
                        }
                    }

                    @keyframes slideFromRight {
                        from {
                            opacity: 0;
                            transform: translateX(100px);
                        }
                        to {
                            opacity: 1;
                            transform: translateX(0);
                        }
                    }

                    @keyframes fadeUp {
                        from {
                            opacity: 0;
                            transform: translateY(20px);
                        }
                        to {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }

                    @keyframes glowPulse {
                        0% { box-shadow: 0 0 5px rgba(255, 102, 0, 0.5); }
                        50% { box-shadow: 0 0 20px rgba(255, 102, 0, 0.8); }
                        100% { box-shadow: 0 0 5px rgba(255, 102, 0, 0.5); }
                    }

                    @keyframes checkmarkBounce {
                        0%, 100% { transform: scale(1); }
                        50% { transform: scale(1.2); }
                    }

                    .about-container {
                        opacity: 0;
                    }

                    .about-container.visible {
                        opacity: 1;
                    }

                    .image-container {
                        position: relative;
                        opacity: 0;
                    }

                    .image-container.visible {
                        animation: slideFromLeft 1s forwards ease-out;
                    }

                    .content-container {
                        opacity: 0;
                    }

                    .content-container.visible {
                        animation: slideFromRight 1s forwards ease-out;
                    }

                    .title-animate {
                        opacity: 0;
                    }

                    .title-animate.visible {
                        animation: fadeUp 0.8s forwards ease-out;
                        animation-delay: 0.3s;
                    }

                    .description-animate {
                        opacity: 0;
                    }

                    .description-animate.visible {
                        animation: fadeUp 0.8s forwards ease-out;
                        animation-delay: 0.5s;
                    }

                    .list-item-animate {
                        opacity: 0;
                    }

                    .list-item-animate.visible {
                        animation: fadeUp 0.8s forwards ease-out;
                    }

                    .image-container:hover img {
                        transform: scale(1.03);
                        transition: transform 0.6s ease-in-out;
                    }

                    .image-container:hover {
                        animation: glowPulse 2s infinite;
                    }

                    .checkmark-icon {
                        transition: all 0.3s ease;
                    }

                    .list-item:hover .checkmark-icon {
                        animation: checkmarkBounce 0.5s ease;
                        color: #ff8533;
                    }

                    .find-out-more {
                        position: relative;
                        transition: all 0.3s ease;
                    }

                    .find-out-more::after {
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 2px;
                        bottom: -2px;
                        left: 0;
                        background-color: #ff6600;
                        transition: width 0.3s ease;
                    }

                    .find-out-more:hover::after {
                        width: 100%;
                    }

                    .find-out-more:hover {
                        color: #ff6600;
                        transform: translateX(5px);
                    }

                    .find-out-more:hover .arrow-icon {
                        transform: translateX(5px);
                        color: #ff6600;
                    }

                    .arrow-icon {
                        transition: transform 0.3s ease;
                    }
                `}
            </style>

            <div className={`container relative md:mt-24 mt-16 about-container ${isVisible ? 'visible' : ''}`}>
                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
                    <div className={`image-container relative overflow-hidden rounded-lg border border-black bg-gradient-to-tl to-orange-600/30 from-black/30 dark:to-orange-600/50 dark:from-black/50 ps-6 pt-6 lg:me-8 ${isVisible ? 'visible' : ''}`}>
                        <img src={aboutImg} className="ltr:rounded-tl-lg rtl:rounded-tr-lg transition-all duration-500" alt="" />
                    </div>

                    <div className={`content-container ${isVisible ? 'visible' : ''}`}>
                        <h3 className={`title-animate mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-darkOrange ${isVisible ? 'visible' : ''}`}>
                            Cybersecurity Consulting,<br /> Awareness, and Training
                        </h3>
                        <p className={`description-animate text-white max-w-xl ${isVisible ? 'visible' : ''}`}>
                            "NexGen Guards provide Comprehensive cybersecurity consulting and customized training programs to: "
                        </p>

                        <ul className="list-none text-white mt-4">
                            {[
                                "Strengthen organizational security",
                                "Ensure compliance",
                                "Build a culture of security awareness"
                            ].map((item, index) => (
                                <li 
                                    key={index}
                                    className={`list-item-animate list-item mb-2 flex items-center ${isVisible ? 'visible' : ''}`}
                                    style={{ animationDelay: `${0.7 + (index * 0.2)}s` }}
                                >
                                    <FiCheckCircle className="checkmark-icon text-darkOrange h-5 w-5 me-2" />
                                    {item}
                                </li>
                            ))}
                        </ul>

                        <div className={`list-item-animate mt-4 ${isVisible ? 'visible' : ''}`} style={{ animationDelay: '1.3s' }}>
                            <Link to="/aboutus" className="find-out-more text-white hover:text-darkOrange font-medium duration-500 inline-flex items-center">
                                Find Out More <MdKeyboardArrowRight className="arrow-icon ms-1" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}