import React, { useState, useRef } from "react";
import { motion, AnimatePresence, useInView } from "framer-motion";
import { 
  FileText, 
  ExternalLink, 
  Shield, 
  Cpu, 
  Globe, 
  Zap,
  ArrowDown
} from "lucide-react";

const researchPapers = [
    {
        id: 1,
        title: "Cybersecurity Laws in Advanced Nations",
        link: "https://www.researchgate.net/publication/384115419_A_survey_of_cybersecurity_laws_regulations_and_policies_in_technologically_advanced_nations_a_case_study_of_Pakistan_to_bridge_the_gap",
        category: ["Cybersecurity", "Policy"],
        icon: <Shield className="text-teal-400" />,
        description: "Comprehensive analysis of cybersecurity regulations across technologically advanced nations.",
        publishedDate: "Sep 2024"
    },
    {
        id: 2,
        title: "Blockchain Bug Bounty Programs",
        link: "https://www.researchgate.net/publication/382126794_A_Survey_of_Bug_Bounty_Programs_in_Strengthening_Cybersecurity_and_Privacy_in_the_Blockchain_Industry",
        category: ["Blockchain", "Security"],
        icon: <Globe className="text-purple-400" />,
        description: "Exploring the effectiveness of bug bounty programs in enhancing blockchain security.",
        publishedDate: "Aug 2024"
    },
    {
        id: 3,
        title: "Anti-Forensics Data Deletion Framework",
        link: "https://www.researchgate.net/publication/382107416_DelSec_An_Anti-Forensics_Data_Deletion_Framework_for_Smartphones_IoT_and_Edge_Devices",
        category: ["Forensics", "IoT"],
        icon: <Cpu className="text-pink-400" />,
        description: "Advanced framework for secure data deletion across multiple device types.",
        publishedDate: "May 2024"
    },
    {
        id: 4,
        title: "Data Center Network Load Balancing",
        link: "https://www.researchgate.net/publication/379158149_Revolutionizing_Data_Center_Networks_Dynamic_Load_Balancing_via_Floodlight_in_SDN_Environment",
        category: ["Networking", "SDN"],
        icon: <Zap className="text-orange-400" />,
        description: "Innovative approach to dynamic load balancing in Software-Defined Networks.",
        publishedDate: "Feb 2024"
    },
    {
        id: 5,
        title: "A Survey of Electric Vehicle Driver-Assisting Smartphone Applications: Vulnerabilities, Risks, and Defensive Strategies",
        link: "https://www.researchgate.net/publication/379843308_A_Survey_of_Electric_Vehicle_Driver-Assisting_Smartphone_Applications_Vulnerabilities_Risks_and_Defensive_Strategies?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSJ9fQ",
        category: ["Electric Vehicles", "Cybersecurity"],
        icon: <Zap className="text-orange-400" />,
        description: "Comprehensive analysis of vulnerabilities in EV driver-assisting apps and defensive strategies.",
        publishedDate: "Mar 2024",
    },
    {
        id: 6,
        title: "Smart Cities: A Novel Framework for Energy Production and Harvesting using Renewable Energy",
        category: ["Smart Cities", "Renewable Energy"],
        icon: <Zap className="text-orange-400" />,
        description: "Proposes innovative methods for energy production and harvesting in smart cities.",
        publishedDate: "Apr 2024",
    },
    {
        id: 7,
        title: "A Cybersecurity Risk Assessment of Electric Vehicle Mobile Applications: Findings and Recommendations",
        link: "https://www.researchgate.net/publication/371239381_A_cybersecurity_risk_assessment_of_electric_vehicle_mobile_applications_findings_and_recommendations?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSJ9fQ",
        category: ["Cybersecurity", "Electric Vehicles"],
        icon: <Zap className="text-orange-400" />,
        description: "Risk assessment of EV mobile apps with actionable recommendations.",
        publishedDate: "May 2024",
    },
    {
        id: 8,
        title: "Emerging Cybersecurity and Privacy Threats to Electric Vehicles and Their Impact on Human and Environmental Sustainability",
        link: "https://www.researchgate.net/publication/367293870_Emerging_Cybersecurity_and_Privacy_Threats_to_Electric_Vehicles_and_Their_Impact_on_Human_and_Environmental_Sustainability?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSJ9fQ",
        category: ["Cybersecurity", "Sustainability"],
        icon: <Zap className="text-orange-400" />,
        description: "Explores the implications of cybersecurity threats on EVs and sustainability.",
        publishedDate: "Jun 2024",
    },
    
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};

const itemVariants = {
  hidden: { y: 50, opacity: 0 },
  visible: { 
    y: 0, 
    opacity: 1,
    transition: {
      type: "spring",
      damping: 12,
      stiffness: 100
    }
  }
};

const CategoryTag = ({ label }) => (
  <motion.span 
    whileHover={{ scale: 1.1 }}
    className="px-2 py-1 text-xs font-semibold 
      bg-white/10 text-white 
      rounded-full mr-2 mb-2 hover:bg-white/20 transition-all"
  >
    {label}
  </motion.span>
);

const ResearchPaperCard = ({ paper, onSelect }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleCardClick = (e) => {
    if (window.innerWidth >= 640) {
      onSelect(paper);
    }
  };

  return (
    <motion.div 
      variants={itemVariants}
      className="relative bg-gradient-to-br from-[#1a1a2e] via-[#16213e] to-[#0f3460] 
        rounded-3xl p-6 overflow-hidden group cursor-pointer
        border border-transparent hover:border-white/10
        transition-all duration-500 transform hover:scale-105 
        shadow-2xl hover:shadow-2xl hover:shadow-purple-500/20"
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      whileHover={{ 
        scale: 1.05,
        rotate: Math.random() > 0.5 ? 1 : -1
      }}
      onClick={handleCardClick}
    >
      <motion.div 
        className={`absolute inset-0 bg-gradient-to-br 
          from-purple-500/10 via-teal-500/10 to-pink-500/10 
          opacity-0 group-hover:opacity-50 
          transition-opacity duration-500 
          pointer-events-none blur-xl`}
        animate={{
          rotate: [0, 5, -5, 0],
          transition: { 
            duration: 5, 
            repeat: Infinity,
            repeatType: "mirror"
          }
        }}
      />

      <div className="relative z-10">
        <motion.div 
          className="flex items-center mb-4"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2 }}
        >
          {React.cloneElement(paper.icon, {
            size: 40,
            className: `${paper.icon.props.className} transition-transform duration-500 
              group-hover:rotate-12 group-hover:scale-110`
          })}
          <h3 className={`ml-4 text-xl font-bold 
            text-white transition-colors duration-500 
            group-hover:text-white/80`}>
            {paper.title}
          </h3>
        </motion.div>

        <motion.p 
          className="text-gray-400 text-sm mb-4 line-clamp-3"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          {paper.description}
        </motion.p>

        <div className="flex flex-wrap items-center justify-between">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            {paper.category.map((cat, index) => (
              <CategoryTag key={index} label={cat} />
            ))}
          </motion.div>

          <div className="flex items-center space-x-2">
            <motion.span 
              className="text-xs text-gray-500"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              {paper.publishedDate}
            </motion.span>
            {paper.link && (
              <motion.a
                href={paper.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => e.stopPropagation()}
                whileHover={{ scale: 1.1 }}
                className="sm:hidden inline-flex items-center 
                  bg-white/10 text-white 
                  px-2 py-1 rounded-full 
                  hover:bg-white/20 transition-all text-xs"
              >
                View <ExternalLink className="ml-1" size={12} />
              </motion.a>
            )}
            <motion.div
              className="text-white/50 group-hover:text-white 
                transition-all duration-500 hidden sm:block"
              whileHover={{ 
                scale: 1.2,
                rotate: [0, 10, -10, 0]
              }}
            >
              <ExternalLink size={16} />
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const ResearchPaperModal = ({ paper, onClose }) => {
  if (!paper) return null;

  return (
    <motion.div
      className="fixed inset-0 z-50 flex items-center justify-center 
        bg-black/70 backdrop-blur-sm p-4 overflow-y-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="bg-gradient-to-br from-[#1a1a2e] via-[#16213e] to-[#0f3460] 
          rounded-3xl w-full max-w-2xl p-6 sm:p-8 relative 
          border border-white/10 shadow-2xl
          mx-4 sm:mx-auto"
        initial={{ 
          scale: 0.7, 
          opacity: 0,
          rotate: -10
        }}
        animate={{ 
          scale: 1, 
          opacity: 1,
          rotate: 0,
          transition: {
            type: "spring",
            damping: 15,
            stiffness: 300
          }
        }}
        exit={{ 
          scale: 0.7, 
          opacity: 0,
          rotate: 10
        }}
      >
        <motion.button
          onClick={onClose}
          whileHover={{ scale: 1.2, rotate: 90 }}
          className="absolute top-4 right-4 text-white/50 
            hover:text-white transition-colors 
            bg-white/10 rounded-full p-2"
        >
          ✕
        </motion.button>

        <div className="flex items-center mb-6">
          {React.cloneElement(paper.icon, {
            size: 48,
            className: `${paper.icon.props.className} mr-4 animate-pulse`,
          })}
          <div>
            <motion.h2 
              className="text-2xl font-bold text-white"
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              {paper.title}
            </motion.h2>
            <motion.p 
              className="text-gray-400 text-sm"
              initial={{ x: -30, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              Published: {paper.publishedDate}
            </motion.p>
          </div>
        </div>

        <motion.p 
          className="text-gray-300 mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          {paper.description}
        </motion.p>

        <motion.div 
          className="flex flex-wrap items-center gap-2 mb-6"
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          {paper.category.map((cat, index) => (
            <CategoryTag key={index} label={cat} />
          ))}
        </motion.div>

        {paper.link && (
          <motion.a
            href={paper.link}
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.1 }}
            className="inline-flex items-center 
              bg-white/10 text-white 
              px-4 py-2 rounded-full 
              hover:bg-white/20 transition-all"
          >
            View Publication <ExternalLink className="ml-2" size={16} />
          </motion.a>
        )}
      </motion.div>
    </motion.div>
  );
};

export default function ResearchPapers() {
  const [selectedPaper, setSelectedPaper] = useState(null);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <motion.div 
      ref={ref}
      className="min-h-screen bg-gradient-to-br from-[#0a0a1a] via-[#0f0f2a] to-[#1a1a3a] 
        text-white py-8 sm:py-12 px-4 sm:px-6 md:px-8 overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: isInView ? 1 : 0 }}
      transition={{ duration: 1 }}
    >
      <motion.div 
        className="container mx-auto max-w-7xl"
        variants={containerVariants}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
      >
<motion.h1 
  variants={itemVariants}
  className="relative text-4xl sm:text-5xl md:text-7xl font-extrabold text-center mt-12 mb-12 sm:mt-16 sm:mb-16 md:mt-20 md:mb-20
    overflow-hidden"
>
  <motion.span
    initial={{ opacity: 0, y: 50 }}
    animate={{ 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.8, 
        ease: "easeOut" 
      } 
    }}
    className="block bg-clip-text text-transparent 
      bg-gradient-to-r from-teal-400 via-purple-500 to-pink-500 
      tracking-tight"
  >
    Research
  </motion.span>
  <motion.span
    initial={{ opacity: 0, y: 50 }}
    animate={{ 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.8, 
        delay: 0.3,
        ease: "easeOut" 
      } 
    }}
    className="block bg-clip-text text-transparent 
      bg-gradient-to-r from-pink-500 via-purple-500 to-teal-400 
      tracking-tight"
  >
    Exploration
  </motion.span>
  
  {/* Subtle Animated Overlay */}
  <motion.div
    initial={{ width: 0 }}
    animate={{ 
      width: '100%',
      transition: { 
        duration: 1.2, 
        delay: 0.6,
        ease: "easeInOut" 
      } 
    }}
    className="absolute bottom-0 left-0 h-1 
      bg-gradient-to-r from-teal-400 via-purple-500 to-pink-500"
  />
</motion.h1>
        <motion.div 
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6 md:gap-8"
          variants={containerVariants}
        >
          {researchPapers.map((paper) => (
            <ResearchPaperCard 
              key={paper.id} 
              paper={paper} 
              onSelect={setSelectedPaper} 
            />
          ))}
        </motion.div>
  
        <AnimatePresence>
          {selectedPaper && window.innerWidth >= 640 && (
            <ResearchPaperModal 
              paper={selectedPaper} 
              onClose={() => setSelectedPaper(null)} 
            />
          )}
        </AnimatePresence>

        {/* Scroll Indicator */}
        {!selectedPaper && (
          <motion.div 
            className="flex justify-center mt-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ 
              opacity: 1, 
              y: [20, -10, 20],
              transition: { 
                duration: 2, 
                repeat: Infinity 
              } 
            }}
          >
            <div className="flex flex-col items-center text-gray-400 hover:text-white transition-colors">
              <span className="text-xs mb-2">Scroll to Explore</span>
              <ArrowDown className="animate-bounce" />
            </div>
          </motion.div>
        )}
      </motion.div>
    </motion.div>
  );
}