import React, { useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import { 
  Award, Search, Filter, 
  FileText, Flag, Globe, Star 
} from "lucide-react";

// Research Papers Section
const blogData = [
  {
    icon: <FileText size={48} />,
    title: "A survey of cybersecurity laws, regulations, and policies in technologically advanced nations",
    description: "Springer · Sep 20, 2024(other author:Farhan Hassan Saeed)",
    categories: ["Cybersecurity", "Policy"]
  },
  {
    icon: <FileText size={48} />,
    title: "A Survey of Bug Bounty Programs in Strengthening Cybersecurity and Privacy in the Blockchain Industry",
    description: "MDPI-Blockchains · Aug 8, 2024",
    categories: ["Blockchain", "Security"]
  },
  {
    icon: <FileText size={48} />,
    title: "DevicesDelSec: An Anti-Forensics Data Deletion Framework for Smartphones, IoT, and Edge Devices",
    description: "IEEE · May 23, 2024",
    categories: ["Cybersecurity", "IoT"]
  },
  {
    icon: <FileText size={48} />,
    title: "Smart Cities: A Novel Framework for Energy Production and Harvesting using Renewable Energy",
    description: "IEEE · Oct 10, 2023",
    categories: ["Smart Cities", "Energy"]
  },
  {
    icon: <FileText size={48} />,
    title: "Revolutionizing Data Center Networks: Dynamic Load Balancing via Floodlight in SDN Environment",
    description: "IEEE · Feb 19, 2024",
    categories: ["Networking", "Cloud Computing"]
  },
  {
    icon: <FileText size={48} />,
    title: "Smartphone Security and Privacy: A Survey on APTs, Sensor-Based Attacks, Side-Channel Attacks, Google Play Attacks, and Defenses",
    description: "Technologies, MDPI. · Jun 12, 2023",
    categories: ["Cybersecurity", "Mobile Security"]
  }
];

// CTF Competitions Section
const ctfCompetitions = [
  {
    icon: <Flag size={48} />,
    title: "Blackhat Mea 2024",
    description: "Secured 6th place in Pakistan, 2nd in Air University Islamabad, and 55th globally",
    categories: ["Cybersecurity", "Competition"]
  },
  {
    icon: <Flag size={48} />,
    title: "Ignite Hackathon 2023",
    description: "3rd position in Red Team, and 5th overall",
    categories: ["Hackathon", "Red Team"]
  },
  {
    icon: <Flag size={48} />,
    title: "BSides Fargo 2.0 USA",
    description: "Secured 4th position in the BSides Fargo 2.0 Hackathon",
    categories: ["Cybersecurity", "Hackathon"]
  }
];

// Pentesting Awards Section
const pentestingAwards = [
  {
    icon: <Flag size={48} />,
    title: "Invitation from NUST",
    description: "Invited to present a research paper at the ICAI Conference 2023 hosted by NUST.",
    categories: ["Conference", "Research"]
  },
  {
    icon: <Flag size={48} />,
    title: "Invitation from FAST",
    description: "Invited to present a research paper at the ICIT Conference 2023 hosted by FAST University.",
    categories: ["Conference", "Research"]
  },
  {
    icon: <Flag size={48} />,
    title: "Invitation from UOL",
    description: "Invited to present a research paper at the ICACS Conference 2024 hosted by The University of Lahore.",
    categories: ["Conference", "Research"]
  },
  {
    icon: <Flag size={48} />,
    title: "Invitation from NUML",
    description: "Invited to present a research paper at the ICECT Conference 2024 hosted by NUML University.",
    categories: ["Conference", "Research"]
  }
];

// Combine all achievements
const achievementsData = [
  {
    category: "Research Papers",
    icon: <FileText />,
    items: blogData
  },
  {
    category: "Competitions",
    icon: <Flag />,
    items: ctfCompetitions
  },
  {
    category: "Academic Invitations",
    icon: <Award />,
    items: pentestingAwards
  }
];

const AchievementsPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Extract unique categories
  const allCategories = useMemo(() => {
    return [...new Set(
      achievementsData
        .flatMap(section => section.items)
        .flatMap(item => item.categories)
    )];
  }, []);

  // Filter achievements
  const filteredAchievements = useMemo(() => {
    return achievementsData.map(section => ({
      ...section,
      items: section.items.filter(item => 
        (selectedCategories.length === 0 || 
         selectedCategories.some(cat => item.categories.includes(cat))) &&
        (item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
         item.description.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    })).filter(section => section.items.length > 0);
  }, [searchTerm, selectedCategories]);

  const toggleCategory = (category) => {
    setSelectedCategories(prev => 
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <motion.header 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-black border-b border-orange-500/20 text-white py-16 px-4"
      >
        <div className="max-w-4xl mx-auto text-center">
          <motion.h1 
            className="text-4xl md:text-5xl font-bold mb-4"
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
          >
            Professional <span className="text-orange-500">Achievements</span>
          </motion.h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto">
            A comprehensive showcase of research, competitions, and academic recognitions
          </p>

          {/* Search and Filter */}
          <div className="mt-8 max-w-xl mx-auto relative">
            <div className="relative">
              <input 
                type="text" 
                placeholder="Search achievements..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-3 rounded-full bg-gray-900 text-white placeholder-gray-500 border border-orange-500/20 focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
              <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500" />
            </div>
          </div>
        </div>
      </motion.header>

      {/* Category Filters */}
      <div className="max-w-4xl mx-auto px-4 py-6">
        <div className="flex flex-wrap gap-2 justify-center">
          {allCategories.map(category => (
            <motion.button
              key={category}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => toggleCategory(category)}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-all ${
                selectedCategories.includes(category)
                  ? 'bg-orange-500 text-black'
                  : 'bg-gray-900 text-gray-300 hover:bg-gray-800 border border-gray-800'
              }`}
            >
              {category}
            </motion.button>
          ))}
        </div>
      </div>

      {/* Achievements Grid */}
      <div className="max-w-5xl mx-auto px-4 py-8">
        {filteredAchievements.map((section, sectionIndex) => (
          <motion.div 
            key={section.category}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: sectionIndex * 0.2 }}
            className="mb-12"
          >
            <div className="flex items-center mb-6 border-b border-orange-500/20 pb-3">
              {React.cloneElement(section.icon, { 
                className: "w-8 h-8 mr-3 text-orange-500" 
              })}
              <h2 className="text-2xl font-bold text-white">
                {section.category}
              </h2>
            </div>

            <div className="grid md:grid-cols-2 gap-6">
              {section.items.map((item, itemIndex) => (
                <motion.div
                  key={item.title}
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: itemIndex * 0.1 }}
                  className="bg-gray-900 rounded-xl overflow-hidden border border-orange-500/20 hover:border-orange-500 transition-all"
                >
                  <div className="p-6">
                    <div className="flex justify-between items-start mb-4">
                      <div className="flex flex-wrap gap-2">
                        {item.categories.map(cat => (
                          <span 
                            key={cat} 
                            className="px-2 py-1 bg-orange-500/10 text-orange-500 text-xs rounded-full"
                          >
                            {cat}
                          </span>
                        ))}
                      </div>
                    </div>

                    <h3 className="text-xl font-bold text-white mb-2">
                      {item.title}
                    </h3>

                    <p className="text-gray-300 mb-4">
                      {item.description}
                    </p>

                    {item.icon && React.cloneElement(item.icon, { 
                      className: "w-12 h-12 text-orange-500 mx-auto my-4" 
                    })}
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        ))}

        {filteredAchievements.length === 0 && (
          <div className="text-center py-16 text-gray-500">
            <Star className="w-16 h-16 mx-auto mb-4 text-orange-500" />
            <p className="text-xl">No achievements found. Try a different search.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AchievementsPage;