import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Shield, Globe, Brain, ChevronRight, ArrowRight,Palette, Code } from 'lucide-react';

const ServicesPage = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [hoveredService, setHoveredService] = useState(null);

  const services = [
    {
      icon: <Globe size={48} />,
      title: 'Web Development',
      description: 'Crafting responsive and intuitive web solutions tailored to your business needs.',
      details: [
        'Custom website design and development',
        'E-commerce solutions',
        'Content Management Systems (CMS)',
        'Progressive Web Applications (PWA)',
        'API development and integration'
      ],
      color: '#ff6600'
    },
    {
      icon: <Shield size={48} />,
      title: 'Cyber Security',
      description: 'Protecting your digital assets with state-of-the-art security measures and protocols.',
      details: [
        'Vulnerability assessments and penetration testing',
        'Security audits and compliance',
        'Incident response and forensics',
        'Security awareness training',
        'Managed security services'
      ],
      color: '#ff6600'
    },
    {
      icon: <Brain size={48} />,
      title: 'AI Development',
      description: 'Harnessing the power of artificial intelligence to drive innovation and efficiency.',
      details: [
        'Machine learning model development',
        'Natural Language Processing (NLP) solutions',
        'Computer vision applications',
        'Predictive analytics',
        'AI-powered chatbots and virtual assistants'
      ],
      color: '#ff6600'
    },
    {
      icon: <Palette size={48} />, // Replace with the actual icon component for UI/UX
      title: 'UI/UX Design',
      description: 'Creating intuitive, visually stunning, and user-centered design experiences.',
      details: [
        'User Research and Persona Development',
        'Wireframing and Prototyping',
        'User Interface (UI) Design',
        'User Experience (UX) Strategy',
        'Responsive Design for Multiple Devices',
        'Interaction Design',
        'Accessibility Design',
        'Design System Creation'
      ],
      color: '#4a90e2'
    },
    {
      icon: <Code size={48} />, // Replace with the actual icon component for CMS Development
      title: 'CMS Development (WordPress, E-commerce)',
      description: 'Custom CMS solutions tailored to manage your content effectively and power robust online stores.',
      details: [
        'Custom WordPress Theme Development',
        'Plugin Customization and Integration',
        'Performance Optimization and Security Hardening',
        'WooCommerce Setup and Customization',
        'E-commerce Platform Selection (Shopify, WooCommerce, Magento)',
        'Product Catalog Management and Payment Gateway Integration',
        'SEO for CMS and E-commerce Websites',
        'Migration and Maintenance'
      ],
      color: '#21759b'
    }
    
  ];

  const ServiceCard = ({ service, index }) => {
    const isSelected = selectedService === index;
  
    return (
      <motion.div
        className="relative w-full md:w-96 bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl overflow-hidden"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: index * 0.2 }}
        onClick={() => setSelectedService(isSelected ? null : index)}
      >
        <div className="absolute inset-0 bg-orange-500/10" />
  
        <div className="p-8">
          <div className="w-16 h-16 rounded-lg bg-orange-500/20 flex items-center justify-center mb-6">
            {React.cloneElement(service.icon, { className: "text-orange-500" })}
          </div>
  
          <h3 className="text-2xl font-bold text-white mb-4">
            {service.title}
          </h3>
  
          <p className="text-gray-300 mb-6">{service.description}</p>
  
          <motion.button
            className="flex items-center text-orange-500 font-semibold group"
            whileHover={{ x: 10 }}
          >
            Learn More
            <ArrowRight
              className="ml-2 group-hover:translate-x-1 transition-transform"
              size={18}
            />
          </motion.button>
        </div>
  
        <AnimatePresence>
          {isSelected && (
            <motion.div
              className="p-8 bg-gray-800/95 absolute inset-0"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
            >
              <h4 className="text-orange-500 font-bold mb-4">Our Offerings:</h4>
              <ul className="space-y-3">
                {service.details.map((detail, i) => (
                  <motion.li
                    key={i}
                    className="flex items-center text-gray-200"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: i * 0.1 }}
                  >
                    <ChevronRight className="text-orange-500 mr-2" size={16} />
                    {detail}
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  };
  

  return (

    <div className="min-h-screen bg-gray-900">
      {/* Hero Section */}
      <motion.section 
        className="relative py-32 md:py-44 overflow-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.div
          className="absolute inset-0 bg-orange-500/5"
          animate={{
            backgroundPosition: ['0% 0%', '100% 100%'],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            repeatType: 'reverse',
          }}
        />
        
        <div className="container mx-auto px-6">
          <motion.div 
            className="text-center"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <motion.h1 
              className="text-5xl md:text-6xl font-bold text-white mb-6"
              animate={{ 
                textShadow: ['0 0 20px rgba(255,102,0,0)', '0 0 20px rgba(255,102,0,0.5)', '0 0 20px rgba(255,102,0,0)'] 
              }}
              transition={{ duration: 2, repeat: Infinity }}
            >
              Our Services
            </motion.h1>
            
            <div className="flex items-center justify-center text-gray-400 space-x-2">
              <Link to="/" className="hover:text-orange-500 transition-colors">Home</Link>
              <ChevronRight size={16} />
              <span className="text-orange-500">Services</span>
            </div>
          </motion.div>
        </div>
      </motion.section>

      {/* Services Grid */}
      <motion.main 
        className="container mx-auto px-6 py-16"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <ServiceCard key={index} service={service} index={index} />
          ))}
        </div>
      </motion.main>

      {/* Particle Effect Background */}
      <div className="fixed inset-0 pointer-events-none">
        {Array.from({ length: 20 }).map((_, i) => (
          <motion.div
            key={i}
            className="absolute w-2 h-2 bg-orange-500/20 rounded-full"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
            }}
            animate={{
              y: [0, Math.random() * 100 - 50],
              x: [0, Math.random() * 100 - 50],
              opacity: [0, 1, 0],
            }}
            transition={{
              duration: Math.random() * 10 + 10,
              repeat: Infinity,
              repeatType: "reverse",
            }}
          />
        ))}
      </div>
    </div>


  );
};

export default ServicesPage;