import React, { useState, useEffect } from 'react';
import { FaQuoteLeft, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

// Placeholder for advisor images (replace with actual imports)
import advisor1 from "../assets/advisors/advisor1.jpg";
import advisor2 from "../assets/advisors/advisor2.jpg";
import advisor3 from "../assets/advisors/advisor3.jpg";

const AdvisorCard = ({ name, role, quote, imageSrc, index }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, index * 200);

    return () => clearTimeout(timer);
  }, [index]);

  return (
    <div
      className={`relative p-4 ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-12'} 
      transition-all duration-700 ease-out`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="relative overflow-hidden rounded-xl bg-black shadow-xl transition-all duration-500 hover:scale-105 group">
        
        {/* Enhanced background effects */}
        <div className="absolute inset-0 opacity-75 bg-[radial-gradient(circle_at_50%_50%,rgba(255,165,0,0.1),transparent_50%)]" />
        <div className={`absolute inset-0 bg-gradient-to-br from-orange-500/20 to-yellow-500/20 
          transition-opacity duration-500 ${isHovered ? 'opacity-100' : 'opacity-0'}`} />

        {/* Animated corner accents */}
        <div className="absolute top-0 left-0 w-8 h-8 border-t-2 border-l-2 border-orange-500/50 
          transition-all duration-500 group-hover:w-12 group-hover:h-12" />
        <div className="absolute bottom-0 right-0 w-8 h-8 border-b-2 border-r-2 border-orange-500/50 
          transition-all duration-500 group-hover:w-12 group-hover:h-12" />

        {/* Larger image container */}
        <div className="relative h-96 overflow-hidden">
          <img src={imageSrc} alt={name} className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110" />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent 
            opacity-60 group-hover:opacity-40 transition-opacity duration-500" />
        </div>

        <div className="relative p-6">
          <h3 className="text-2xl font-bold text-orange-500 mb-2 group-hover:text-orange-400 transition-colors duration-300">{name}</h3>
          <p className="text-white text-lg mb-4">{role}</p>
          <div className="relative">
            <FaQuoteLeft className="text-orange-500 text-3xl absolute -top-4 -left-2 opacity-50" />
            <p className="text-white italic mb-4 pl-8"><a>{quote}</a></p>
          </div>
          <div className="flex justify-end space-x-3">
            <button className="p-2 rounded-full bg-orange-500/20 hover:bg-orange-500 
              transition-all duration-300 hover:scale-110 hover:rotate-6 group/icon">
              <FaLinkedinIn size={16} className="text-white" />
            </button>
            <button className="p-2 rounded-full bg-orange-500/20 hover:bg-orange-500 
              transition-all duration-300 hover:scale-110 hover:rotate-6 group/icon">
              <FaTwitter size={16} className="text-white" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AdvisorSection = () => {
  const advisors = [
    {
      name: "Dr. Zunaira Jalil",
      role: "Professor",
      quote: <a href="mailto:zunerajalil@au.edu.pk">zunerajalil@au.edu.pk</a>,
      imageSrc: advisor1
    },
    {
      name: "Mr. Zia Muhammad",
      role: "Lecturer",
      quote: <a href="mailto:zia.muhammad@au.edu.pk">zia.muhammad@au.edu.pk</a>,
      imageSrc: advisor2
    },
    {
      name: "Dr Khawaja Mansoor",
      role: "Assistant Professor",
      quote: <a href="mailto:mansoor.hassan@au.edu.pk">mansoor.hassan@au.edu.pk</a>,
      imageSrc: advisor3
    }
  ];

  return (
    <div className="min-h-screen relative overflow-hidden text-white py-16" style={{background: 'black'}}>
      
      {/* Animated background */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(255,165,0,0.15),transparent_50%)] animate-pulse-slow" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_60%,rgba(255,165,0,0.1),transparent_50%)] animate-pulse-slower" />
      </div>

      <div className="max-w-6xl mx-auto px-4 relative">
        {/* New top-level heading */}
        <h1 className="text-5xl md:text-6xl font-bold text-white mb-8 text-center animate-fade-in-up">
          Advisory Board
        </h1>

        <div className="text-center mb-12">
          <h2 className="text-4xl md:text-5xl font-bold text-orange-500 mb-4 relative">
            {["Our", "Esteemed", "Advisors"].map((word, i) => (
              <span key={i} className={`inline-block animate-fade-in-up opacity-0`} 
                    style={{ animationDelay: `${i * 200}ms`, animationFillMode: 'forwards' }}>
                {word}{' '}
              </span>
            ))}
          </h2>
          <div className="h-1 w-20 mx-auto rounded-full bg-gradient-to-r from-orange-500 to-yellow-500 
            animate-expand-width" />
          <p className="mt-4 text-white max-w-2xl mx-auto animate-fade-in" 
             style={{ animationDelay: '600ms', animationFillMode: 'forwards' }}>
            Guiding our vision with expertise and insight, our advisors bring a wealth of experience 
            from diverse industries to shape our strategic direction.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {advisors.map((advisor, index) => (
            <AdvisorCard key={index} {...advisor} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdvisorSection;

// Add these additional custom animations to your global CSS or Tailwind config
<style jsx global>{`
  @keyframes pulse-slower {
    0%, 100% { opacity: 0.3; }
    50% { opacity: 0.7; }
  }

  @keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes fade-in-up {
    from { 
      opacity: 0;
      transform: translateY(20px);
    }
    to { 
      opacity: 1;
      transform: translateY(0);
    }
  }

  .animate-pulse-slower { animation: pulse-slower 6s ease-in-out infinite; }
  .animate-fade-in { animation: fade-in 1s ease-out forwards; }
  .animate-fade-in-up { animation: fade-in-up 1s ease-out forwards; }
`}</style>