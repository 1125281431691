import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { FiCheckCircle, FiPlay } from "react-icons/fi";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import Teams from "../components/teams";
import AdvisorSection from "../components/advisorCard";

// Import your images
import AboutImg from "../assets/images/about.png";
import client1 from "../assets/images/client/bilal.png";
import client2 from "../assets/images/client/02.jpg";
import client3 from "../assets/images/client/03.jpg";

// Import other components
import ClientsTwo from "../components/clientTwo";
import Blogs from "../components/blogs";
import Footer from "../components/footer";
import Navbar from "../components/navbar";

export default function AboutUs() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("dark");
    document.documentElement.classList.remove("light");
  }, []);

  const [isOpen, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);



  const pageStyle = {
    backgroundColor: "#121212",
    color: "#ffffff",
    fontFamily: "'Poppins', sans-serif",
  };

  const sectionStyle = {
    padding: "80px 0",
    position: "relative",
  };

  const containerStyle = {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "0 20px",
  };

  const headingStyle = {
    fontSize: "2.5rem",
    fontWeight: "700",
    color: "#ff6600",
    marginBottom: "1rem",
    textShadow: "2px 2px 4px rgba(0,0,0,0.1)",
  };

  const subHeadingStyle = {
    fontSize: "1.2rem",
    color: "#ffffff",
    marginBottom: "2rem",
  };

  const cardStyle = {
    backgroundColor: "rgba(255,255,255,0.05)",
    borderRadius: "10px",
    padding: "30px",
    boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
    transition: "all 0.3s ease",
  };

  const buttonStyle = {
    backgroundColor: "#ff6600",
    color: "#ffffff",
    padding: "12px 24px",
    borderRadius: "30px",
    border: "none",
    cursor: "pointer",
    transition: "all 0.3s ease",
    fontWeight: "600",
    textTransform: "uppercase",
    letterSpacing: "1px",
  };

  return (
    <div style={pageStyle}>
      <div
        className="min-h-screen relative overflow-hidden text-white"
        style={{
          background: "linear-gradient(to bottom right, #000000,#000000)",
        }}
      >
        {/* Hero Section */}
        <section className="relative md:py-44 py-32 bg-[url('../../assets/images/bg/aboutusbg.png')] bg-no-repeat bg-bottom bg-cover">
          <div className="container relative">
            <div className="grid grid-cols-1 text-center mt-6">
              <div>
                <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-white mb-0">
                  About Us
                </h5>
              </div>
              <ul className="tracking-[0.5px] mb-0 inline-block mt-5">
                <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white">
                  <Link to="/">NexGen Guards</Link>
                </li>
                <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
                  <i className="mdi mdi-chevron-right"></i>
                </li>
                <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white">
                  About us
                </li>
              </ul>
              
            </div>
          </div>
        </section>

        <section style={sectionStyle}>
          <div style={containerStyle}>
            <div style={{ display: "flex", alignItems: "center", gap: "50px" }}>
              <div style={{ flex: 1, position: "relative" }}>
                <img
                  src={AboutImg}
                  alt="About Us"
                  style={{
                    width: "80%",
                    borderRadius: "10px",
                    boxShadow: "0 20px 40px rgba(0,0,0,0.2)",
                  }}
                />
                

                {/*
                
                <button
                  onClick={() => setOpen(true)}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#ff6600",
                    color: "#ffffff",
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    border: "none",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "24px",
                  }}
                >
                  <FiPlay />
                </button>
                
                */}
              </div>
              <div style={{ flex: 1 }}>
                <h2 style={headingStyle}>NexGen Guards</h2>
                <h3 style={{ ...subHeadingStyle, fontWeight: "600" }}>
                  Using AI for innovation
                </h3>
                <p style={{ marginBottom: "1rem", lineHeight: "1.8" }}>
                  "NexGen Guards is revolutionizing the way we approach
                  technology, combining AI with top-notch services to create
                  solutions that meet modern demands."
                </p>
                <ul style={{ listStyle: "none", padding: 0 }}>
                  {[
                    "Web Development Solutions for Tomorrow",
                    "Leading Cyber Security Practices",
                    "AI Integration for Enhanced Performance",
                  ].map((item, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <FiCheckCircle
                        style={{ color: "#ff6600", marginRight: "10px" }}
                      />
                      {item}
                    </li>
                  ))}
                </ul>
                <button style={buttonStyle}>Learn More</button>
              </div>
            </div>
          </div>
        </section>

        <section
          style={{ ...sectionStyle, backgroundColor: "rgba(255,102,0,0.05)" }}
        >
          <div style={containerStyle}>
            <h2
              style={{
                ...headingStyle,
                textAlign: "center",
                marginBottom: "3rem",
              }}
            >
              Our Services
            </h2>
            <div className="flex justify-center mb-8 flex-wrap">
              {["Web Development", "Cyber Security", "AI Solutions"].map(
                (service, index) => (
                  <button
                    key={index}
                    onMouseEnter={() => setActiveIndex(index)}
                    style={{
                      ...buttonStyle,
                      backgroundColor:
                        activeIndex === index ? "#ff6600" : "transparent",
                      color: activeIndex === index ? "#ffffff" : "#ff6600",
                      margin: "0 10px",
                    }}
                  >
                    {service}
                  </button>
                )
              )}
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6">
              {activeIndex === 0 && (
                <>
                  <div className="mb-5 md:mb-0" style={cardStyle}>
                    <h4 style={{ ...headingStyle, fontSize: "1.5rem" }}>
                      Responsive Design
                    </h4>
                    <p>
                      Our web development services focus on creating responsive
                      and user-friendly designs.
                    </p>
                  </div>
                  <div style={cardStyle}>
                    <h4 style={{ ...headingStyle, fontSize: "1.5rem" }}>
                      SEO Optimization
                    </h4>
                    <p>
                      Optimizing websites for search engines to improve
                      visibility and traffic.
                    </p>
                  </div>
                </>
              )}
              {activeIndex === 1 && (
                <>
                  <div className="mb-5 md:mb-0" style={cardStyle}>
                    <h4 style={{ ...headingStyle, fontSize: "1.5rem" }}>
                      Risk Assessment
                    </h4>
                    <p>
                      We provide thorough risk assessments to identify
                      vulnerabilities and enhance security measures.
                    </p>
                  </div>
                  <div style={cardStyle}>
                    <h4 style={{ ...headingStyle, fontSize: "1.5rem" }}>
                      Incident Response
                    </h4>
                    <p>
                      Our incident response team is available 24/7 to address
                      and mitigate security breaches.
                    </p>
                  </div>
                </>
              )}
              {activeIndex === 2 && (
                <>
                  <div className="mb-5 md:mb-0" style={cardStyle}>
                    <h4 style={{ ...headingStyle, fontSize: "1.5rem" }}>
                      AI-Powered Analytics
                    </h4>
                    <p>
                      Leveraging AI to analyze data and provide actionable
                      insights for better decision-making.
                    </p>
                  </div>
                  <div style={cardStyle}>
                    <h4 style={{ ...headingStyle, fontSize: "1.5rem" }}>
                      Chatbot Development
                    </h4>
                    <p>
                      We create AI-driven chatbots that enhance customer
                      engagement and support.
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>

        <Teams />
        <AdvisorSection />

        <Footer />
      </div>
    </div>
  );
}
