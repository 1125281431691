import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowDown } from '../assets/icons/vander';

export default function Faq() {
    const [activeTab, setActiveTab] = useState("accordionData");
    const [activeIndex, setActiveIndex] = useState(0);
    const accordionData = [
        {
            id: 1,
            title: 'How is outstanding service delivery ensured by NexGen Guard?',
            desc: 'NexGen Guard is dedicated to providing excellent service by customizing our solutions to meet the unique requirements of every customer. We offer complete, individualized solutions that guarantee a safe and productive digital workplace by using industry best practices, cutting-edge tools, and a committed support staff.'
        },
        {
            id: 2,
            title: 'What steps does NexGen Guard take to earn the trust of its clients?',
            desc: 'Our goal is to earn the trust of our clients. We place a high value on openness, offer ongoing assistance, and concentrate on enduring, fruitful collaborations. Strong relationships with each client are facilitated by frequent updates, transparent communication, and customized services.'
        },
        {
            id: 3,
            title: 'In what ways does NexGen Guard raise awareness about cybersecurity?',
            desc: 'To help clients and their staff comprehend and address possible cybersecurity risks, NexGen Guard provides specialized training and educational programs. Organizations are empowered to actively maintain their security posture thanks to these initiatives.'
        },
        {
            id: 4,
            title: 'How does NexGen Guard maintain its innovative edge?',
            desc: 'By implementing new technology and continuously improving our processes, we remain at the forefront of industry innovations. To make sure our solutions are not only efficient but also flexible, our staff always keeps an eye out for new dangers and applies the newest techniques and technologies.'
        },
        {
            id: 5,
            title: 'In what ways does NexGen Guard foster the career development of its employees?',
            desc: 'We promote a culture of ongoing education and cooperation. By means of continuous training, certifications, and team-building exercises, we guarantee that our staff is knowledgeable, driven, and prepared to offer clients with highest quality services.'
        },
        {
            id: 6,
            title: 'How does NexGen Guard guarantee customized solutions for various customer requirements?',
            desc: 'NexGen Guard is committed to developing unique solutions that meet the unique needs of every customer. Our method entails careful consultation to completely comprehend the needs of the client, followed by the application of best practices to provide the most efficient solutions.'


        },

        {
            id: 7,
            title: 'What steps does NexGen Guard take to create enduring relationships with its clients?',
            desc: 'For us, establishing lasting partnerships is a fundamental principle. By means of open procedures, frequent client check-ins, and proactive assistance, NexGen Guard guarantees that each client has faith in our dedication to their security and success.'
        },

        {
            id: 8,
            title: 'How does NexGen Guard continue to remain proactive in tackling new threats to cybersecurity?',
            desc: 'By keeping abreast of emerging threats and adapting our strategies appropriately, we are dedicated to proactive protection. Frequent updates to threat intelligence and flexible security protocols keep our clients well-protected.'
        },


    ];

    const ServicesFaqs = [
        {
            id: 1,
            title: 'What kinds of advisory services does NexGen Guard provide for cybersecurity?',
            desc: 'From strategic assessments to the implementation of specialized protection measures, we offer a wide range of cybersecurity consulting services. We provide penetration testing, vulnerability assessments, and advice to make sure your companys defenses are strong and effective against attacks.'
        },
        {
            id: 2,
            title: 'How is the security of NexGen Guards web development services guaranteed?',
            desc: 'One of the main priorities of our web building process is security. To build feasible and user-friendly applications with robust security, we prioritize data protection, implement safe coding techniques, and carry out in-depth vulnerability evaluations'
        },
        {
            id: 3,
            title: 'Which AI solutions are offered by NexGen Guard?',
            desc: 'NexGen Guard focuses in developing data analytics tools and chatbots driven by AI that improve customer engagement and operational efficiency. Our AI solutions facilitate customer interaction, expedite processes, and offer insightful data for better decision-making.'
        },
        {
            id: 4,
            title: 'How are incident response and threat detection handled by NexGen Guard?',
            desc: 'Our incident response and threat detection services are made to react quickly. We effectively mitigate incidents to lessen disruption and preserve security by keeping an eye on possible risks in real time and putting organized response mechanisms into place.'
        },
        {
            id: 5,
            title: 'Which encryption services are provided by NexGen Guard?',
            desc: 'We offer cutting-edge data encryption services to safeguard private data while maintaining data integrity and confidentiality. Our encryption procedures are made to satisfy compliance requirements and offer our clients strong data protection.'
        },
        {
            id: 6,
            title: 'What advantages do vulnerability evaluations from NexGen Guard offer?',
            desc: 'Our vulnerability evaluations find possible flaws in the infrastructure of your company. We offer thorough analysis and solutions to bolster your security and lower the probability of successful cyber attacks.'


        },

        {
            id: 7,
            title: 'How does NexGen Guard go about doing penetration tests?',
            desc: 'NexGen Guard starts with a thorough evaluation to find weaknesses, mimics actual attacks, and generates comprehensive reports with practical suggestions to successfully strengthen security measures.'
        },

        {
            id: 8,
            title: 'How is secure web development approached by NexGen Guard?',
            desc: 'From design to deployment, we incorporate security throughout every phase of development. To produce dependable and user-friendly online applications, this entails consistent code reviews, safe coding techniques, and adherence to industry security requirements.'
        },


    ];
    
    return (
        
        <>
            <style>
                {`
                    .bg-dark {
                        background-color: #000000; /* Black background */
                    }
                    .text-white {
                        color: #ffffff; /* White text color */
                    }
                    .text-accent-orange {
                        color: #ff6600; /* Orange text */
                    }
                    .accordion-button {
                        background-color: #1F1F1F; /* Dark background for the accordion */
                        color: #ff6600; /* Orange color for the active accordion */
                        border-radius: 8px;
                        padding: 1rem;
                        transition: background-color 0.3s ease;
                    }
                    .accordion-button:hover {
                        background-color: #333333; /* Slightly lighter on hover */
                    }
                    .accordion-content {
                        background-color: #121212; /* Darker background for content */
                        color: #cccccc; /* Light gray for text */
                        padding: 1rem;
                    }
                `}
            </style>
                <div className="min-h-screen relative overflow-hidden text-white" style={{background: 'linear-gradient(to bottom right, #000000,#000000)'}}>

        
            <div className="container relative md:mt-24 mt-16 bg-dark text-white p-10">
                <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center md:gap-[30px]">
                    <div className="lg:col-span-4 md:mb-0 mb-8">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-accent-orange">Have a question?</h3>

                        <p className="text-slate-400 max-w-xl mx-auto mb-6">Artificial intelligence makes it fast and easy to create content for your blog, social media, website, and more!</p>

                        <Link to="/contact" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-orange-600 border-gray-100 dark:border-gray-800 hover:border-orange-600 dark:hover:border-orange-600 text-accent-orange dark:text-white hover:text-white rounded-md">
                            Contact Us
                        </Link>
                    </div>




                    <div className="lg:col-span-8 md:mt-0 mt-8">

                    <div className="tab-container">
                            <button
                                className={`tab-button ${activeTab === "research" ? "active" : ""}`}
                                onClick={() => setActiveTab("accordionData")}
                            >
                              
                               About NexGen
                            </button>
                            <button
                                className={`tab-button ${activeTab === "ctf" ? "active" : ""}`}
                                onClick={() => setActiveTab("ServicesFaqs")}
                            >
                                
                                About Services
                            </button>
                    </div>


                     {activeTab === "accordionData" && accordionData.map((item, index) => (
                                <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4" key={index}>
                                    <h2 className="text-base font-semibold">
                                        <button 
                                            type="button" 
                                            onClick={() => setActiveIndex(item.id)} 
                                            className={`accordion-button flex justify-between items-center w-full font-medium text-start ${activeIndex === item.id ? "text-accent-orange" : "text-white"}`}
                                        >
                                            <span>{item.title}</span>
                                            <MdKeyboardArrowDown className={`${activeIndex === item.id ? "rotate-180" : ""} w-4 h-4 shrink-0`} />
                                        </button>
                                    </h2>
                                    <div className={activeIndex === item.id ? "accordion-content" : "hidden"}>
                                        <div className="p-5">
                                            <p className="text-slate-400 dark:text-gray-400">{item.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {activeTab === "ServicesFaqs" && ServicesFaqs.map((item, index) => (
                                <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4" key={index}>
                                    <h2 className="text-base font-semibold">
                                        <button 
                                            type="button" 
                                            onClick={() => setActiveIndex(item.id)} 
                                            className={`accordion-button flex justify-between items-center w-full font-medium text-start ${activeIndex === item.id ? "text-accent-orange" : "text-white"}`}
                                        >
                                            <span>{item.title}</span>
                                            <MdKeyboardArrowDown className={`${activeIndex === item.id ? "rotate-180" : ""} w-4 h-4 shrink-0`} />
                                        </button>
                                    </h2>
                                    <div className={activeIndex === item.id ? "accordion-content" : "hidden"}>
                                        <div className="p-5">
                                            <p className="text-slate-400 dark:text-gray-400">{item.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            </div>

        </>
    );
}
