import React from "react";
import { Link } from "react-router-dom";
import aboutImg from "../assets/homepage/home2.png";
import { FiCheckCircle, MdKeyboardArrowRight } from '../assets/icons/vander';

export default function AboutTwo() {
    return (
        <>
            <style>
                {`
                    .text-darkOrange {
                        color: #ff6600!important; /* Override Tailwind's text color */
                    }
                    .text-white {
                        color: #FFFFFF !important; /* Override Tailwind's text color */
                    }
                    .text-black {
                        color: #000000 !important; /* Override Tailwind's text color */
                    }
                `}
            </style>
            <div className="container relative md:mt-24 mt-16">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
                    <div className="relative order-1 md:order-2">
                        <div className="relative overflow-hidden rounded-lg border border-black bg-gradient-to-tl to-orange-600/30 from-black/30 dark:to-orange-600/50 dark:from-black/50 pe-6 pt-6 lg:ms-8">
                            <img src={aboutImg} className="ltr:rounded-tr-lg rtl:rounded-tl-lg" alt="" />
                        </div>
                    </div>

                    <div className="order-2 md:order-1">
                        <h4 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-darkOrange">
                        Secure and Relaible Applications integrated with latest technologies
                        </h4>
                        <p className="text-white">
                            At NEXGEN Guards, we are committed to empowering businesses with cutting-edge technological solutions. Our expertise spans across web development, cybersecurity, artificial intelligence (AI), and other transformative technologies 
                        </p>

                        {/*

                         <ul className="list-none text-slate-400 mt-4">
                            <li className="mb-2 flex items-center">
                                <FiCheckCircle className="text-darkOrange h-5 w-5 me-2" />
                                Digital Marketing Solutions for Tomorrow
                            </li>
                            <li className="mb-2 flex items-center">
                                <FiCheckCircle className="text-darkOrange h-5 w-5 me-2" />
                                Our Talented & Experienced Marketing Agency
                            </li>
                            <li className="mb-2 flex items-center">
                                <FiCheckCircle className="text-darkOrange h-5 w-5 me-2" />
                                Create your own skin to match your brand
                            </li>
                        </ul>
                        
                        */}
                       

                        <div className="mt-4">
                            <Link to="/aboutus" className="hover:text-darkOrange font-medium duration-500 inline-flex items-center">
                                Find Out More <MdKeyboardArrowRight className="ms-1 text-white" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
