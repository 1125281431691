import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Menu, X, ChevronDown } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

const mobileMenuVariants = {
  hidden: { opacity: 0, x: "100%" },
  visible: { opacity: 1, x: 0 },
};

const linkContainerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const linkItemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0 },
};

const Navbar = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMoreDropdownOpen, setIsMoreDropdownOpen] = useState(false);
  const mobileMenuRef = useRef(null);
  const menuButtonRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };

    const handleClickOutside = (event) => {
      // Close mobile menu if click is outside the menu and menu button
      if (
        isMobileMenuOpen &&
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target) &&
        menuButtonRef.current &&
        !menuButtonRef.current.contains(event.target)
      ) {
        setIsMobileMenuOpen(false);
      }
    };

    // Add event listeners
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listeners
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  const navLinks = [
    { to: "/", label: "Home" },
    { to: "/aboutus", label: "About Us" },
    { to: "/services", label: "Our Services" },
    { to: "/contact", label: "Contact" },
  ];

  const moreLinks = [
    { to: "/teampage", label: "Our Team" },
    { to: "/blog", label: "Company Blog" },
    { to: "/researches", label: "Research Insights" },
    { to: "/achievements", label: "Company Milestones" },
  ];

  const navbarStyle = {
    backgroundColor: `rgba(26, 26, 26, 0.9)`,
    transition: "background-color 0.3s ease-in-out",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    // Close more dropdown when mobile menu is toggled
    setIsMoreDropdownOpen(false);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <>
      <style>{`
        .sidebar-menu {
          background-color: #1a1a1a !important;
          color: white !important;
          overflow-y: auto;
          max-height: calc(100vh - 4rem);
        }

        .sidebar-menu a {
          color: white !important;
          transition: background-color 0.3s ease-in-out;
          padding: 0.75rem 1rem;
          display: block;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }

        .sidebar-menu a:hover {
          background-color: #ff6600 !important;
        }

        .sidebar-menu .close-button {
          position: absolute;
          top: 1rem;
          right: 1rem;
          color: white;
          transition: color 0.3s ease-in-out;
        }

        .sidebar-menu .close-button:hover {
          color: #ff6600;
        }
      `}</style>

      <motion.div
        style={navbarStyle}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <nav className="max-w-[1200px] mx-auto p-4 flex justify-between items-center">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <span className="text-2xl font-bold text-orange-500">
              NEX<span className="text-white">GEN</span> GUARDS
            </span>
          </motion.div>

          <div className="absolute left-1/2 transform -translate-x-1/2 hidden md:flex items-center gap-6">
            {navLinks.map((link) => (
              <Link
                key={link.to}
                to={link.to}
                className="relative text-white text-lg font-medium transition-all hover:text-orange-500"
              >
                {link.label}
              </Link>
            ))}

            <div
              className="relative group"
              onClick={() => setIsMoreDropdownOpen(!isMoreDropdownOpen)}
            >
              <button className="flex items-center text-white text-lg font-medium transition-all hover:text-orange-500">
                More <ChevronDown className="ml-1" size={18} />
              </button>
              {isMoreDropdownOpen && (
                <div className="absolute top-full left-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-10">
                  {moreLinks.map((link) => (
                    <Link
                      key={link.to}
                      to={link.to}
                      className="block px-4 py-2 text-white hover:bg-gray-700 transition-colors"
                      onClick={() => setIsMoreDropdownOpen(false)}
                    >
                      {link.label}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="md:hidden">
            <motion.button 
              ref={menuButtonRef}
              onClick={toggleMobileMenu} 
              whileTap={{ scale: 0.9 }}
              aria-label={isMobileMenuOpen ? "Close Menu" : "Open Menu"}
            >
              {isMobileMenuOpen ? (
                <X size={32} color="white" />
              ) : (
                <Menu size={32} color="white" />
              )}
            </motion.button>
          </div>

          <AnimatePresence>
            {isMobileMenuOpen && (
              <motion.div
                ref={mobileMenuRef}
                className="sidebar-menu fixed top-0 right-0 w-full h-full p-4 overflow-y-auto bg-black z-50"
                variants={mobileMenuVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
              >
                
                {/* Navigation Links */}
                <motion.div
                  variants={linkContainerVariants}
                  initial="hidden"
                  animate="visible"
                  className="space-y-6 pt-4"
                >
                  <div>
                    <h3 className="text-xl font-semibold mb-4 text-orange-500">Main Navigation</h3>
                    {navLinks.map((link) => (
                      <motion.div key={link.to} variants={linkItemVariants} className="mb-2">
                        <Link
                          to={link.to}
                          className="block text-white text-lg font-medium py-3 border-b border-white/10 hover:bg-gray-800 transition-colors"
                          onClick={closeMobileMenu}
                        >
                          {link.label}
                        </Link>
                      </motion.div>
                    ))}
                  </div>

                  <div>
                    <h3 className="text-xl font-semibold mb-4 text-orange-500">More Options</h3>
                    {moreLinks.map((link) => (
                      <motion.div key={link.to} variants={linkItemVariants} className="mb-2">
                        <Link
                          to={link.to}
                          className="block text-white text-lg font-medium py-3 border-b border-white/10 hover:bg-gray-800 transition-colors"
                          onClick={closeMobileMenu}
                        >
                          {link.label}
                        </Link>
                      </motion.div>
                    ))}
                  </div>

                  <div className="mt-6 text-center">
                    <motion.button
                      onClick={closeMobileMenu}
                      whileTap={{ scale: 0.95 }}
                      className="w-full bg-orange-500 text-white font-semibold py-3 rounded-md hover:bg-orange-600 transition-colors"
                    >
                      Close
                    </motion.button>
                  </div>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </nav>
      </motion.div>
    </>
  );
};

export default Navbar;