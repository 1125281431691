import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import { Clock, Calendar, ArrowRight, Bookmark } from 'lucide-react';

const BlogCard = ({ item, index }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="relative group"
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <div className="relative overflow-hidden rounded-xl bg-gray-900 h-full">
        {/* Gradient Overlay */}
        <motion.div 
          className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black/90 z-10"
          animate={{
            opacity: isHovered ? 1 : 0.8
          }}
          transition={{ duration: 0.3 }}
        />

        {/* Background Pattern */}
        <motion.div
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ff6600' fill-opacity='0.2'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")"
          }}
        />

        {/* Featured Image */}
        <div className="relative overflow-hidden aspect-video">
          <motion.img
            src={item.image}
            alt={item.title}
            className="object-cover w-full h-full transform"
            animate={{
              scale: isHovered ? 1.1 : 1,
            }}
            transition={{ duration: 0.4 }}
          />
        </div>

        {/* Content */}
        <div className="relative z-20 p-6">
          {/* Categories */}
          <div className="flex items-center gap-2 mb-4">
            <motion.div
              className="text-xs font-semibold px-3 py-1 rounded-full bg-orange-500/20 text-orange-500"
              whileHover={{ scale: 1.05 }}
            >
              AI
            </motion.div>
            <motion.div
              className="text-xs font-semibold px-3 py-1 rounded-full bg-orange-500/20 text-orange-500"
              whileHover={{ scale: 1.05 }}
            >
              Marketing
            </motion.div>
          </div>

          {/* Title */}
          <motion.h3
            className="text-xl font-bold mb-3 text-white"
            animate={{
              color: isHovered ? '#ff6600' : '#ffffff'
            }}
          >
            <Link to={item.link} className="hover:text-orange-500 transition-colors">
              {item.title}
            </Link>
          </motion.h3>

          {/* Excerpt */}
          <motion.p
            className="text-gray-400 mb-4 line-clamp-2"
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: isHovered ? 1 : 0,
              height: isHovered ? 'auto' : 0
            }}
          >
            {item.excerpt || "Discover the latest insights and trends in AI and marketing technology..."}
          </motion.p>

          {/* Meta Information */}
          <div className="flex items-center justify-between text-sm text-gray-400">
            <div className="flex items-center gap-4">
              <span className="flex items-center gap-1">
                <Clock className="w-4 h-4 text-orange-500" />
                <span>5 min read</span>
              </span>
              <span className="flex items-center gap-1">
                <Calendar className="w-4 h-4 text-orange-500" />
                <span>{item.date}</span>
              </span>
            </div>
            
            <motion.button
              className="p-2 rounded-full bg-orange-500/20 text-orange-500"
              whileHover={{ scale: 1.1, backgroundColor: 'rgba(255, 102, 0, 0.3)' }}
              whileTap={{ scale: 0.95 }}
            >
              <Bookmark className="w-4 h-4" />
            </motion.button>
          </div>

          {/* Read More Button */}
          <motion.div
            className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-gray-900 to-transparent p-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{
              opacity: isHovered ? 1 : 0,
              y: isHovered ? 0 : 20
            }}
          >
            <Link 
              to={item.link}
              className="flex items-center justify-center gap-2 w-full py-2 bg-orange-500 text-white rounded-lg font-semibold transition-transform hover:bg-orange-600"
            >
              Read More
              <ArrowRight className="w-4 h-4" />
            </Link>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

const Blogs = () => {
  return (
    <div className="relative py-20">
      {/* Background Elements */}
      <motion.div
        className="absolute inset-0 overflow-hidden"
        animate={{
          background: [
            'radial-gradient(circle at 0% 0%, rgba(255, 102, 0, 0.1) 0%, transparent 50%)',
            'radial-gradient(circle at 100% 100%, rgba(255, 102, 0, 0.1) 0%, transparent 50%)'
          ]
        }}
        transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
      />

      <div className="container mx-auto px-4">
        {/* Section Header */}
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="inline-block"
          >
            <span className="text-orange-500 font-semibold">Stay Updated</span>
          </motion.div>
          
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.1 }}
            className="text-4xl md:text-5xl font-bold text-white mt-2 mb-4"
          >
            Latest News & Articles
          </motion.h2>

          <motion.div
            initial={{ width: 0 }}
            whileInView={{ width: '80px' }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="h-1 bg-orange-500 mx-auto mb-6"
          />

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className="text-gray-400 max-w-2xl mx-auto"
          >
            Stay ahead of the curve with our latest insights into artificial intelligence, 
            cybersecurity, and digital transformation.
          </motion.p>
        </div>

       
        

        {/* View All Button */}
        <motion.div
          className="text-center mt-12"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <Link 
            to="/blog"
            className="inline-flex items-center gap-2 px-6 py-3 bg-orange-500 text-white rounded-lg font-semibold hover:bg-orange-600 transition-colors"
          >
            View All Articles
            <ArrowRight className="w-5 h-5" />
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default Blogs;