import React from 'react';
import { motion } from 'framer-motion';

const Globes = () => {
  // Generate points for the globe
  const generatePoints = () => {
    const points = [];
    const segments = 12;
    const rings = 8;
    
    for (let i = 0; i < rings; i++) {
      const radius = Math.sin((i / rings) * Math.PI);
      const y = Math.cos((i / rings) * Math.PI);
      
      for (let j = 0; j < segments; j++) {
        const angle = (j / segments) * Math.PI * 2;
        points.push({
          x: radius * Math.cos(angle),
          y: y,
          z: radius * Math.sin(angle),
        });
      }
    }
    return points;
  };

  const points = generatePoints();

  return (
    <div className="w-full h-screen absolute top-0 left-0 overflow-hidden">
      <motion.div
        className="absolute w-full h-full"
        animate={{
          rotateY: 360,
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          ease: "linear",
        }}
      >
        <svg
          viewBox="-1.2 -1.2 2.4 2.4"
          className="w-full h-full opacity-50"
          style={{ transform: 'scale(0.8)' }}
        >
          {/* Glowing orange sphere background */}
          <motion.circle
            cx="0"
            cy="0"
            r="1"
            fill="url(#orangeGlow)"
            animate={{
              scale: [1, 1.05, 1],
              opacity: [0.3, 0.4, 0.3],
            }}
            transition={{
              duration: 3,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />

          {/* Grid lines */}
          {points.map((point, i) => (
            points.map((point2, j) => {
              if (j === i + 1 || j === i + 12) {
                const distance = Math.sqrt(
                  Math.pow(point2.x - point.x, 2) +
                  Math.pow(point2.y - point.y, 2) +
                  Math.pow(point2.z - point.z, 2)
                );
                if (distance < 0.7) {
                  return (
                    <motion.line
                      key={`${i}-${j}`}
                      x1={point.x}
                      y1={point.y}
                      x2={point2.x}
                      y2={point2.y}
                      stroke="#ff6600"
                      strokeWidth="0.01"
                      strokeOpacity="0.5"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: [0.2, 0.5, 0.2] }}
                      transition={{
                        duration: 3,
                        repeat: Infinity,
                        delay: Math.random() * 2,
                      }}
                    />
                  );
                }
              }
              return null;
            })
          ))}

          {/* Points */}
          {points.map((point, i) => (
            <motion.circle
              key={i}
              cx={point.x}
              cy={point.y}
              r="0.02"
              fill="#ff6600"
              initial={{ opacity: 0 }}
              animate={{
                opacity: [0.2, 1, 0.2],
                scale: [1, 1.5, 1],
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                delay: Math.random() * 2,
              }}
            />
          ))}

          {/* Gradient definition */}
          <defs>
            <radialGradient id="orangeGlow" cx="50%" cy="50%" r="50%">
              <stop offset="0%" stopColor="#ff6600" stopOpacity="0.3" />
              <stop offset="100%" stopColor="#ff6600" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>
      </motion.div>
    </div>
  );
};

export default Globes;