import React, { useState, useEffect } from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import client1 from "../assets/team/bilal.png";
import client2 from "../assets/team/maila.png";
import client3 from "../assets/team/ali.png";
import client4 from "../assets/team/alishba.png";
import client5 from "../assets/team/faheem.png";
import client6 from "../assets/team/ahmed.png";
import client7 from "../assets/team/farhan.png";

import { motion } from "framer-motion";

// Sparkles component
const Sparkles = ({ size, className, style }) => (
  <div
    className={`sparkle ${className}`}
    style={{
      width: size,
      height: size,
      borderRadius: "50%",
      backgroundColor: "transparent",
      boxShadow: `0 0 ${size}px yellow`,
      position: "absolute",
      ...style,
    }}
  />
);

// TeamMember component
const TeamMember = ({ name, title, description, imageSrc, index, socialLinks }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, index * 200);
    return () => clearTimeout(timer);
  }, [index]);

  return (
    <div
      className={`relative p-4 ${
        isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-12"
      } transition-all duration-700 ease-out`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="relative overflow-hidden rounded-xl bg-gradient-to-br from-gray-900 to-gray-800 shadow-xl transition-all duration-500 hover:scale-105 group">
        {/* Card animations */}
        <div className="absolute inset-0 opacity-75 bg-[radial-gradient(circle_at_50%_50%,rgba(255,140,50,0.1),transparent_50%)]" />
        <div
          className={`absolute inset-0 bg-gradient-to-br from-orange-500/20 to-yellow-500/20 transition-opacity duration-500 ${
            isHovered ? "opacity-100" : "opacity-0"
          }`}
        />
        <div className="relative aspect-square overflow-hidden">
          <img
            src={imageSrc}
            alt={`${name}'s profile`}
            className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
          />
        </div>

        <div className="relative p-6 text-center">
          <h3 className="text-xl font-semibold text-white mb-2 group-hover:text-orange-400 transition-colors duration-300">
            {name}
          </h3>
          <p className="text-orange-400 opacity-75">{title}</p>
          <p className="text-orange-400 opacity-75">{description}</p>
          <div className="flex justify-center space-x-4 mt-4">
            {socialLinks.facebook && (
              <a
                href={socialLinks.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 rounded-full bg-gray-800/50 hover:bg-orange-600 transition-all duration-300 hover:scale-110"
              >
                <FaFacebookF size={20} className="text-white" />
              </a>
            )}
            {socialLinks.instagram && (
              <a
                href={socialLinks.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 rounded-full bg-gray-800/50 hover:bg-orange-600 transition-all duration-300 hover:scale-110"
              >
                <FaInstagram size={20} className="text-white" />
              </a>
            )}
            {socialLinks.linkedin && (
              <a
                href={socialLinks.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 rounded-full bg-gray-800/50 hover:bg-orange-600 transition-all duration-300 hover:scale-110"
              >
                <FaLinkedinIn size={20} className="text-white" />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// EnhancedTeamMembersHeading component
const EnhancedTeamMembersHeading = () => {
  const letters = "Team Members".split("");
  return (
    <div className="text-center mb-20 relative">
      <div className="relative inline-block">
        {letters.map((letter, index) => (
          <motion.span
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.5,
              delay: index * 0.1,
              ease: [0.6, -0.05, 0.01, 0.99],
            }}
            className="text-6xl font-extrabold"
          >
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-orange-500 via-yellow-500 to-red-500">
              {letter === " " ? "\u00A0" : letter}
            </span>
          </motion.span>
        ))}
      </div>
    </div>
  );
};

// Teams Component
const Teams = () => {
  const teamData = [
    {
      name: "Bilal Saleem",
      title: "C.E.O NexGen Gaurds",
      description: "Penetration Tester | Cyber Security Researcher | Ethical Hacker",
      imageSrc: client1,
      socialLinks: {
        instagram: "https://www.instagram.com/bilal_malick08/",
        linkedin: "https://www.linkedin.com/in/m-bilalmalik/",
      },
    },
    {
      name: "Maila Zahra",
      title: "CVM and CoFounder",
      description: "Network Security Analyst | Cybersecurity Researcher",
      imageSrc: client2,
      socialLinks: {
        facebook: "https://www.facebook.com/share/15PKeKpPJN/",
        instagram: "https://www.instagram.com/by_zahraaaa/profilecard/?igsh=dTdwMHZ0aGxqbzRv",
      },
    },
    {
      name: "Muhammad Ali Hassan",
      title: "CMO NexGen Gaurds",
      description: "Microsoft Learn Student Ambassador @MLSA AU",
      imageSrc: client3,
      socialLinks: {
        instagram: "https://www.instagram.com/aleeh7n/",
        linkedin: "https://www.linkedin.com/in/aleeh7n/",
      },
    },
    {
      name: "Alishba Rehman",
      title: "CSM NexGen Gaurds",
      description: "Co-Lead Research Club @AUCSS | Writer",
      imageSrc: client4,
      socialLinks: {
        instagram: "https://www.instagram.com/alish_kim_/profilecard/?igsh=bGhkajc0ZW16YTR2",
        linkedin: "https://www.linkedin.com/in/alishreh",
      },
    },
    {
      name: "Fahim Ahmed Khan",
      title: "Web Developer NexGen Gaurds",
      description: "Mern Stack Developer",
      imageSrc: client5,
      socialLinks: {
        facebook: "https://www.facebook.com/share/XNh584pYTuwKCY71/?mibextid=LQQJ4d",
        instagram: "https://www.instagram.com/fahee.mxahmed._/profilecard/?igsh=MXBpanhhd2M0YmxwbA==",
        linkedin: "https://www.linkedin.com/in/fahim-ahmed-a8107b270?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
      },
    },
    {
      name: "Farhan",
      title: "AI SPECIALIST",
      imageSrc: client7,
      socialLinks: {
        instagram: "https://www.instagram.com/farhan.hassan96/",
        linkedin: "https://www.linkedin.com/in/farhan-hassan-saeed?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
      },
    },
    {
      name: "Ahmad Hassan Iqbal",
      title: "SEO Specialist / PPC Expert / Email Marketing",
      imageSrc: client6,
      socialLinks: {
        facebook: "https://www.facebook.com/ahmed.jajua.5",
        linkedin: "www.linkedin.com/in/ahmed-hassan-iqbal-68b1a921a",
      },
    },
  ];

  return (
    <div
      className="min-h-screen relative overflow-hidden text-white"
      style={{
        background: "linear-gradient(to bottom right, #000000, #000000)",
      }}
    >
      <EnhancedTeamMembersHeading />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-4">
        {teamData.map((member, index) => (
          <TeamMember key={index} {...member} index={index} />
        ))}
      </div>
    </div>
  );
};

export default Teams;
