import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import contactImg from "../assets/images/contact.svg";
import bgImage from "../assets/images/bg/aboutusbg.png";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { FiHexagon, FiPhone, FiMail, FiMapPin } from "react-icons/fi"; // Corrected import path for icons

export default function Contact() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("dark");
    document.documentElement.classList.remove("light");
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#1a1a1a",
        color: "white",
        minHeight: "100vh",
        fontFamily: "Arial, sans-serif",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Hero Section */}
      <section className="relative md:py-44 py-32 bg-[url('../../assets/images/bg/aboutusbg.png')] bg-no-repeat bg-bottom bg-cover">
        <div className="container relative">
          <div className="grid grid-cols-1 text-center mt-6">
            <div>
              <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-white mb-0">
                Contact Us
              </h5>
            </div>
            <ul className="tracking-[0.5px] mb-0 inline-block mt-5">
              <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white">
                <Link to="/">NexGen Guards</Link>
              </li>
              <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
                <i className="mdi mdi-chevron-right"></i>
              </li>
              <li
                className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white"
                aria-current="page"
              >
                Contact Us
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section
        style={{
          padding: "60px 0",
          backgroundColor: "black",
          color: "white",
          backgroundImage:
            "radial-gradient(circle at 10% 20%, rgba(255, 102, 0, 0.1) 0%, rgba(0, 0, 0, 0) 80%)",
        }}
      >
        <div
          style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 20px" }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "50px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                transform: "rotate(-5deg)",
                transition: "transform 0.3s ease-in-out",
              }}
            >
              <img
                src={contactImg}
                alt="Contact"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  boxShadow: "0 10px 30px rgba(255, 102, 0, 0.3)",
                  borderRadius: "15px",
                }}
              />
            </div>

            <div
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.05)",
                borderRadius: "15px",
                boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
                padding: "40px",
                backdropFilter: "blur(5px)",
              }}
            >
              <h3
                style={{
                  marginBottom: "30px",
                  fontSize: "36px",
                  fontWeight: "700",
                  color: "#ff6600",
                  textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)",
                }}
              >
                Our Contact Details
              </h3>

              <div style={{ marginBottom: "25px" }}>
                <h4
                  style={{
                    fontSize: "20px",
                    color: "white",
                    marginBottom: "10px",
                  }}
                >
                  Main Office
                </h4>
                <p style={{ color: "#cccccc" }}>
                  Air University, Business Incubation Center
                </p>
                <p style={{ color: "#cccccc" }}>
                  Islamabad
                </p>
              </div>

              <div style={{ marginBottom: "25px" }}>
                <h4
                  style={{
                    fontSize: "20px",
                    color: "white",
                    marginBottom: "10px",
                  }}
                >
                  Phone Numbers
                </h4>
                <p style={{ color: "#cccccc" }}>
                  CEO: +92 3454801844
                </p>
                <p style={{ color: "#cccccc" }}>
                  CVO: +92 3325467650
                </p>
              </div>

              <div style={{ marginBottom: "25px" }}>
                <h4
                  style={{
                    fontSize: "20px",
                    color: "white",
                    marginBottom: "10px",
                  }}
                >
                  Email Addresses
                </h4>
                <p style={{ color: "#cccccc" }}>Info: info@techcompany.com</p>
                <p style={{ color: "#cccccc" }}>
                  Support: nexgenGaurds@gmail.com
                </p>
              </div>

              <div>
                <h4
                  style={{
                    fontSize: "20px",
                    color: "white",
                    marginBottom: "10px",
                  }}
                >
                  Business Hours
                </h4>
                <p style={{ color: "#cccccc" }}>
                  Monday - Friday: 9:00 AM - 6:00 PM (PST)
                </p>
                <p style={{ color: "#cccccc" }}>
                  Saturday: 10:00 AM - 2:00 PM (PST)
                </p>
                <p style={{ color: "#cccccc" }}>Sunday: Closed</p>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            maxWidth: "1200px",
            margin: "60px auto 0",
            padding: "0 20px",
          }}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: FiPhone,
                title: "Phone",
                content: "Call us anytime for immediate assistance",
                link: "tel:+92 3325467650",
                linkText: "+92 3325467650",
              },
              {
                icon: FiMail,
                title: "Email",
                content: "Send us an email, we'll respond within 24 hours",
                link: "mailto:contact@techcompany.com",
                linkText: "contact@techcompany.com",
              },
              {
                icon: FiMapPin,
                title: "Visit Us",
                content: "Come see us at our main office location",
                link: "https://goo.gl/maps/abcdefg",
                linkText: "Get Directions",
              },
            ].map((item, index) => (
              <div
                className="mb-5 sm:mb-0"
                key={index}
                style={{
                  textAlign: "center",
                  padding: "30px",
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                  borderRadius: "15px",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "80px",
                    height: "80px",
                    backgroundColor: "rgba(255, 102, 0, 0.1)",
                    borderRadius: "50%",
                    marginBottom: "20px",
                  }}
                >
                  <item.icon style={{ fontSize: "36px", color: "#ff6600" }} />
                </div>

                <h5
                  style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    marginBottom: "15px",
                    color: "white",
                  }}
                >
                  {item.title}
                </h5>
                <p style={{ color: "#cccccc", marginBottom: "20px" }}>
                  {item.content}
                </p>
                <Link
                  to={item.link}
                  style={{
                    color: "#ff6600",
                    textDecoration: "none",
                    fontWeight: "500",
                    transition: "color 0.3s",
                    ":hover": { color: "#ff8533" },
                  }}
                >
                  {item.linkText}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
