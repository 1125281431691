import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import { Clock, Calendar, ArrowRight, Bookmark, Filter } from 'lucide-react';
import blog1 from "../assets/images/blog/1.jpg"
import blog2 from "../assets/images/blog/2.jpg"
import blog3 from "../assets/images/blog/3.jpg"
import blog4 from "../assets/images/blog/4.jpg"
import blog5 from "../assets/images/blog/5.jpg"
import blog6 from "../assets/images/blog/6.jpg"
import blog7 from "../assets/images/blog/7.jpg"
import blog8 from "../assets/images/blog/8.jpg"

const blogData = [
    {
        id: 1,
        image: blog1,
        title: '2024 State of Threat Detection',
        client: 'https://thehackernews.uk/threat-detection-2024',
        categories: ['Cybersecurity', 'Threat Detection'],
        excerpt: 'An in-depth analysis of the latest trends and technologies in threat detection for 2024.',
        readTime: 5,
    },
    {
        id: 2,
        image: blog2,
        title: "THN Recap: Top Cybersecurity Threats, Tools, and Practices (Nov 11 - Nov 17)",
        client: 'https://thehackernews.com/2024/11/thn-recap-top-cybersecurity-threats_18.html',
        categories: ['Cybersecurity', 'Threat Intelligence'],
        excerpt: 'A comprehensive roundup of the most significant cybersecurity developments from mid-November.',
        readTime: 7,
    },
    {
        id: 3,
        image: blog3,
        title: 'Delsec: An Anti-Forensics Data Deletion Framework for Smartphones, IoT, and Edge Devices.',
        client: 'https://ieeexplore.ieee.org/abstract/document/10581213',
        categories: ['Privacy', 'IoT Security'],
        excerpt: 'Exploring a novel framework for secure data deletion across various device types.',
        readTime: 6,
    },
    {
        id: 4,
        image: blog4,
        title: 'Cyber-entrepreneurship in the age of CyberAI',
        client: 'https://thecyberwire.com/stories/75aee2ca896f4fe8b3230b29fc791866/cyber-entrepreneurship-in-the-age-of-cyberai',
        categories: ['AI', 'Entrepreneurship'],
        excerpt: 'How artificial intelligence is transforming the landscape of cybersecurity entrepreneurship.',
        readTime: 4,
    },
    {
        id: 5,
        image: blog5,
        title: 'XM Cyber on Operationalizing The Continuous Threat Exposure Management (CTEM)',
        client: 'https://thehackernews.uk/ctem-xmcyber',
        categories: ['Threat Management', 'Cybersecurity Strategy'],
        excerpt: 'A deep dive into continuous threat exposure management and its practical implementation.',
        readTime: 6,
    },
    {
        id: 6,
        image: blog6,
        title: 'A Cybersecurity Risk Assessment of Electric Vehicle Mobile Applications',
        client: 'https://ieeexplore.ieee.org/document/10136682',
        categories: ['IoT Security', 'Automotive Tech'],
        excerpt: 'Examining the cybersecurity vulnerabilities in electric vehicle mobile applications.',
        readTime: 5,
    },
    {
        id: 7,
        image: blog7,
        title: 'Emerging Cybersecurity and Privacy Threats to Electric Vehicles and Their Impact on Human and Environmental Sustainability.',
        client: 'https://www.mdpi.com/1996-1073/16/3/1113',
        categories: ['Privacy', 'Automotive Tech'],
        excerpt: 'Investigating the broader implications of cybersecurity threats on electric vehicle ecosystems.',
        readTime: 7,
    },
    {
        id: 8,
        image: blog8,
        title: 'The Future of Cybersecurity: Predictions for 2025 and Beyond',
        client: 'https://www.cyberdefensemagazine.com/the-future-of-cybersecurity-predictions-for-2025-and-beyond/',
        categories: ['Trend Analysis', 'Future Tech'],
        excerpt: 'Exploring emerging trends and predictions that will shape cybersecurity in the coming years.',
        readTime: 6,
    }
];

const BlogPage = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
  
    // Extract unique categories
    const categories = [...new Set(blogData.flatMap(post => post.categories))];
  
    // Filter posts based on category and search term
    const filteredPosts = blogData.filter(post => 
      (!selectedCategory || post.categories.includes(selectedCategory)) &&
      post.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    return (
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="min-h-screen bg-gray-950 text-white"
      >
        {/* Header Section */}
        <motion.header 
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          transition={{ type: "spring", stiffness: 100 }}
          className="pt-24 pb-16 sm:pt-32 sm:pb-20 text-center relative overflow-hidden"
        >
          {/* Animated Background */}
          <motion.div
            className="absolute inset-0 bg-gradient-to-br from-black via-gray-900 to-orange-900/20 opacity-50"
            animate={{
              background: [
                'linear-gradient(to bottom right, rgba(0,0,0,1), rgba(255,102,0,0.2))',
                'linear-gradient(to bottom right, rgba(255,102,0,0.2), rgba(0,0,0,1))'
              ]
            }}
            transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
          />
  
          <div className="container relative z-10 mx-auto px-4">
          <motion.h1 
  className="text-5xl md:text-6xl font-bold mb-6 mt-10"
  initial={{ scale: 0.8, opacity: 0 }}
  animate={{ scale: 1, opacity: 1 }}
  transition={{ delay: 0.2 }}
>
  NexGen <span className="text-orange-500">Insights</span>
</motion.h1>
            
            <motion.p 
              className="text-base sm:text-xl text-gray-300 max-w-2xl mx-auto mb-8"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              Cutting-edge thoughts at the intersection of technology and innovation
            </motion.p>
  
            {/* Search and Filter Section */}
            <motion.div 
              className="flex justify-center items-center space-x-4 px-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
            >
              <div className="relative flex-grow max-w-md w-full">
                <input 
                  type="text" 
                  placeholder="Search articles..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full px-4 py-2 sm:py-3 rounded-full bg-gray-800 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500"
                />
                <Filter className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500" />
              </div>
            </motion.div>
          </div>
        </motion.header>
  
        {/* Category Filter */}
        <motion.div 
          className="container mx-auto px-4 py-8 flex justify-center flex-wrap gap-3"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
        >
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setSelectedCategory(null)}
            className={`px-4 py-2 rounded-full text-sm transition-all ${
              !selectedCategory 
              ? 'bg-orange-500 text-black' 
              : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
            }`}
          >
            All Posts
          </motion.button>
          {categories.map((category) => (
            <motion.button
              key={category}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setSelectedCategory(category)}
              className={`px-4 py-2 rounded-full text-sm transition-all ${
                selectedCategory === category 
                ? 'bg-orange-500 text-black' 
                : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
              }`}
            >
              {category}
            </motion.button>
          ))}
        </motion.div>
  
        {/* Blog Posts Grid */}
        <div className="container mx-auto px-4 py-12">
          <motion.div 
            className="grid sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8"
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0 },
              visible: { 
                opacity: 1,
                transition: {
                  delayChildren: 0.3,
                  staggerChildren: 0.1
                }
              }
            }}
          >
            {filteredPosts.map((post, index) => (
              <motion.div
                key={post.id}
                variants={{
                  hidden: { y: 20, opacity: 0 },
                  visible: { y: 0, opacity: 1 }
                }}
              >
                <BlogCard item={post} index={index} />
              </motion.div>
            ))}
          </motion.div>
        </div>
  
        {filteredPosts.length === 0 && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center py-20 text-gray-500"
          >
            No articles found. Try a different search or category.
          </motion.div>
        )}
      </motion.div>
    );
  };
  
  // Individual Blog Card Component
  const BlogCard = ({ item, index }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    // Function to handle external link navigation
    const handleLinkClick = () => {
      window.open(item.client, '_blank', 'noopener,noreferrer');
    };
  
    return (
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: index * 0.1 }}
        className="relative group"
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        onClick={handleLinkClick} // Add click handler for mobile
      >
        <div className="relative overflow-hidden rounded-xl bg-gray-900 h-full cursor-pointer">
          {/* Gradient Overlay */}
          <motion.div 
            className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black/90 z-10"
            animate={{
              opacity: isHovered ? 1 : 0.8
            }}
            transition={{ duration: 0.3 }}
          />
  
          {/* Background Pattern */}
          <motion.div
            className="absolute inset-0 opacity-10"
            style={{
              backgroundImage: "url(\"data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ff6600' fill-opacity='0.2'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/svg%3E\")"
            }}
          />
  
          {/* Featured Image */}
          <div className="relative overflow-hidden aspect-video">
            <motion.img
              src={item.image}
              alt={item.title}
              className="object-cover w-full h-full transform"
              animate={{
                scale: isHovered ? 1.1 : 1,
              }}
              transition={{ duration: 0.4 }}
            />
          </div>
  
          {/* Content */}
          <div className="relative z-20 p-6">
            {/* Categories */}
            <div className="flex items-center gap-2 mb-4">
              {item.categories.map(category => (
                <motion.div
                  key={category}
                  className="text-xs font-semibold px-3 py-1 rounded-full bg-orange-500/20 text-orange-500"
                  whileHover={{ scale: 1.05 }}
                >
                  {category}
                </motion.div>
              ))}
            </div>
  
            {/* Title */}
            <motion.h3
              className="text-xl font-bold mb-3 text-white"
              animate={{
                color: isHovered ? '#ff6600' : '#ffffff'
              }}
            >
              {item.title}
            </motion.h3>
  
            {/* Excerpt */}
            <motion.p
              className="text-gray-400 mb-4 line-clamp-2"
              initial={{ opacity: 0.7, height: 'auto' }}
              animate={{
                opacity: isHovered ? 1 : 0.7,
              }}
              transition={{ duration: 0.3 }}
            >
              {item.excerpt}
            </motion.p>
  
            {/* Meta Information */}
            <div className="flex items-center justify-between text-sm text-gray-400">
              <div className="flex items-center gap-4">
                <span className="flex items-center gap-1">
                  <Clock className="w-4 h-4 text-orange-500" />
                  <span>{item.readTime} min read</span>
                </span>
                <span className="flex items-center gap-1">
                  <Calendar className="w-4 h-4 text-orange-500" />
                  <span>{item.date}</span>
                </span>
              </div>
              
              <motion.button
                className="p-2 rounded-full bg-orange-500/20 text-orange-500"
                whileHover={{ scale: 1.1, backgroundColor: 'rgba(255, 102, 0, 0.3)' }}
                whileTap={{ scale: 0.95 }}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent card click when bookmarking
                }}
              >
                <Bookmark className="w-4 h-4" />
              </motion.button>
            </div>
  
           {/* Read More Button */}
           <motion.div
              className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-gray-900 to-transparent p-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: isHovered ? 1 : 0,
                y: isHovered ? 0 : 20
              }}
            >
              <button 
                onClick={handleLinkClick}
                className="flex items-center justify-center gap-2 w-full py-2 bg-orange-500 text-white rounded-lg font-semibold transition-transform hover:bg-orange-600"
              >
                Read More
                <ArrowRight className="w-4 h-4" />
              </button>
            </motion.div>
          </div>
        </div>
      </motion.div>
    );
  };
  export default BlogPage;