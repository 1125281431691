import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

function App() {
  return (
    <div>
      {/* Other content of your website */}

      <a
        href="https://wa.me/+923454801844" // Replace with your specific phone number, e.g., https://wa.me/15551234567
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          backgroundColor: '#25d366', // WhatsApp color
          color: 'white',
          fontSize: '40px',
          padding: '10px',
          borderRadius: '50%',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          zIndex: 1000,
          textAlign: 'center',
          textDecoration: 'none',
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp />
      </a>
    </div>
  );
}

export default App;
