import React, { useState, useEffect } from "react";
import about1 from '../assets/homepage/card1.jpeg';
import about2 from '../assets/homepage/card2.jpeg';
import about3 from '../assets/homepage/card3.jpeg';

const Features = ({ classlist }) => {
    const [animate, setAnimate] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setAnimate(true);
        
        // Add intersection observer for scroll animations
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    }
                });
            },
            { threshold: 0.1 }
        );

        const element = document.querySelector('.features-container');
        if (element) {
            observer.observe(element);
        }

        return () => {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, []);

    const featuresData = [
        {
            image: about1,
            title: "AI-Powered Efficiency in Web Development",
            desc: "At NEXGEN Guards, our innovative solutions can reduce development time and improve site performance, making your digital presence stronger and more dynamic."
        },
        {
            image: about2,
            title: "40% More Secure Digital Environment",
            desc: "With advanced cybersecurity measures, NEXGEN Guards helps you safeguard critical assets and sensitive data. Our AI-backed threat detection and response protocols increase your protection by 40%, ensuring your business operates securely in a digital world."
        },
        {
            image: about3,
            title: "5x Improved Customer Engagement",
            desc: "Harness the power of AI to understand your customers and build loyalty. NEXGEN Guards' AI solutions analyze customer data to help you tailor experiences and boost retention."
        },
    ];

    return (
        <div className={`${classlist} features-container relative`}>
            <style jsx>{`
                @keyframes float {
                    0% { transform: translateY(0px); }
                    50% { transform: translateY(-10px); }
                    100% { transform: translateY(0px); }
                }

                @keyframes pulse {
                    0% { transform: scale(1); }
                    50% { transform: scale(1.05); }
                    100% { transform: scale(1); }
                }

                @keyframes slideIn {
                    from {
                        opacity: 0;
                        transform: translateY(50px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                .features-container {
                    background-color: transparent;
                    color: white;
                    transition: all 0.5s ease;
                }

                .features-card {
                    background-color: #1a1a1a;
                    border: 1px solid #333;
                    border-radius: 8px;
                    overflow: hidden;
                    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
                    animation: float 6s ease-in-out infinite;
                }

                .features-card:hover {
                    transform: translateY(-10px) scale(1.02);
                    box-shadow: 0 10px 30px rgba(255, 102, 0, 0.3);
                    border-color: #ff6600;
                }

                .features-title {
                    color: #ff6600;
                    font-size: 1.25rem;
                    font-weight: 600;
                    transition: all 0.3s ease;
                }

                .features-highlight {
                    color: #ff6600;
                    text-shadow: 0 0 10px rgba(255, 102, 0, 0.5);
                    animation: pulse 2s infinite;
                }

                .slide-in {
                    opacity: 0;
                    transform: translateY(50px);
                }

                .slide-in.active {
                    animation: slideIn 0.8s forwards;
                }

                .features-image {
                    transition: transform 0.5s ease;
                }

                .features-card:hover .features-image {
                    transform: scale(1.05);
                }

                .features-desc {
                    color: #cccccc;
                    transition: color 0.3s ease;
                }

                .features-card:hover .features-desc {
                    color: #ffffff;
                }
            `}</style>

            <div className={`grid grid-cols-1 pb-6 text-center slide-in ${isVisible ? 'active' : ''}`}>
                <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                    AI + WebDevelopment + Cybersecurity = <br /> 
                    outstanding <span className="features-highlight">NexGen guards</span>
                </h3>
                <p className="features-desc max-w-xl mx-auto">
                    We would make your 
                </p>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                {featuresData.map((item, index) => (
                    <div
                        className={`features-card slide-in ${isVisible ? 'active' : ''}`}
                        style={{ 
                            animationDelay: `${index * 0.2}s`,
                            animationDuration: '6s',
                            animationDelay: `${index * 0.5}s`
                        }}
                        key={index}
                    >
                        <div className="p-6 pb-0">
                            <img 
                                src={item.image} 
                                className="features-image rounded-t-md shadow-md w-full" 
                                alt={item.title}
                            />
                        </div>
                        <div className="p-6">
                            <h5 className="features-title">{item.title}</h5>
                            <p className="features-desc mt-3">{item.desc}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Features;