import blog1 from "../assets/images/blog/1.jpg"
import blog2 from "../assets/images/blog/2.jpg"
import blog3 from "../assets/images/blog/3.jpg"
import blog4 from "../assets/images/blog/4.jpg"
import blog5 from "../assets/images/blog/5.jpg"
import blog6 from "../assets/images/blog/6.jpg"
import blog7 from "../assets/images/blog/7.jpg"
import blog8 from "../assets/images/blog/8.jpg"


export const blogData = [
    {
        id:1,
        image:blog1,
        title:'2024 State of Threat Detection',
        client:'https://thehackernews.uk/threat-detection-2024',
    },
    {
        id:2,
        image:blog2,
        title:"THN Recap: Top Cybersecurity Threats, Tools, and Practices (Nov 11 - Nov 17)",
        client:'https://thehackernews.com/2024/11/thn-recap-top-cybersecurity-threats_18.html  ',
    },
    {
        id:3,
        image:blog3,
        title:'Delsec: An Anti-Forensics Data Deletion Framework for Smartphones, IoT, and Edge Devices.',
        client:'https://ieeexplore.ieee.org/abstract/document/10581213',
    },
    {
        id:4,
        image:blog4,
        title:'Cyber-entrepreneurship in the age of CyberAI',
        client:'https://thecyberwire.com/stories/75aee2ca896f4fe8b3230b29fc791866/cyber-entrepreneurship-in-the-age-of-cyberai',
    },
    {
        id:5,
        image:blog5,
        title:'XM Cyber on Operationalizing The Continuous Threat Exposure Management (CTEM) ',
        client:'https://thehackernews.uk/ctem-xmcyber',
    },
    {
        id:6,
        image:blog6,
        title:'A Cybersecurity Risk Assessment of Electric Vehicle Mobile Applications',
        client:'https://ieeexplore.ieee.org/document/10136682',
    },
    {
        id:7,
        image:blog7,
        title:'Emerging Cybersecurity and Privacy Threats to Electric Vehicles and Their Impact on Human and Environmental Sustainability.',
        client:'https://www.mdpi.com/1996-1073/16/3/1113',
    },
    {
        id:8,
        image:blog8,
        title:'The Future of Cybersecurity: Predictions for 2025 and Beyond',
        client:'https://www.cyberdefensemagazine.com/the-future-of-cybersecurity-predictions-for-2025-and-beyond/',
    },
  
]