import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from 'framer-motion';
import { Shield, Code, Lock, Globe, ChevronDown } from 'lucide-react';
import { Link } from "react-router-dom";
import AchievementsSection from "../components/achievementSection";
import Globes from "../components/globes";
import TestimonialsSection from "../components/testimonials";

import Navbar from "../components/navbar";
import BrandLogo from "../components/brandLogo";
import Features from "../components/features";
import AboutOne from "../components/aboutOne";
import AboutTwo from "../components/aboutTwo";
import AmazingFeatures from "../components/amazingFeatures";
import AboutThree from "../components/aboutThree";
import Pricing from "../components/pricing";
import Faq from "../components/Faq";
import Blogs from "../components/blogs";
import Footer from "../components/footer";




const GlowingButton = ({ children, className }) => (
  <motion.button
    className={`relative group inline-block ${className}`}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <motion.span
      className="absolute inset-0 bg-orange-500 rounded-md blur-sm opacity-50 group-hover:opacity-75 transition duration-1000 group-hover:duration-200"
      style={{
        zIndex: -1,
        transformOrigin: 'center',
      }}
      animate={{
        scale: [1, 1.1, 1],
        rotate: [0, 5, -5, 0],
      }}
      transition={{
        duration: 3,
        repeat: Infinity,
        repeatType: "reverse",
      }}
    />
    <span className="relative z-10 block px-6 py-2 md:px-8 md:py-3 bg-gradient-to-r from-orange-500 to-orange-600 rounded-md text-white font-semibold text-sm md:text-base">
      {children}
    </span>
  </motion.button>
);

const CyclingText = () => {
  const words = ["Cyber Website", "AI", "Web Development"];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div style={{
      height: 'clamp(4rem, 20vw, 8rem)', // Increased height range
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
      marginTop: 'clamp(0.5rem, 2vw, 1rem)',
      marginBottom: 'clamp(0.5rem, 2vw, 1rem)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <AnimatePresence mode="wait">
        <motion.span
          key={words[index]}
          style={{
            position: 'absolute',
            width: '100%',
            fontSize: 'clamp(2rem, 8vw, 4rem)', // Increased font size range
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 'clamp(0.5rem, 2vw, 1rem)',
            textAlign: 'center',
            lineHeight: '1.2', // Added line height for better spacing
            minHeight: 'clamp(3rem, 15vw, 6rem)' // Added minimum height
          }}
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <span style={{
            background: 'linear-gradient(to right, #f97316, #ea580c)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
            display: 'inline-block',
            whiteSpace: 'nowrap', // Prevents text wrapping
            transform: 'scale(clamp(0.8, 0.8 + 1vw, 1))', // Adds slight scaling for better fit
            maxWidth: '100%',
            overflow: 'visible'
          }}>
            {words[index]}
          </span>
        </motion.span>
      </AnimatePresence>
    </div>
  );
};

const FloatingIcon = ({ Icon, top, left, size, color }) => (
  <motion.div
    className="absolute"
    style={{ top: `${top}%`, left: `${left}%` }}
    animate={{
      y: [0, 15, 0],
      rotate: [0, 10, -10, 0],
      scale: [1, 1.2, 1],
    }}
    transition={{
      duration: 5,
      repeat: Infinity,
      repeatType: "reverse",
    }}
  >
    <Icon size={size} color={color} />
  </motion.div>
);

const ScrollDownIndicator = () => (
  <motion.div
    className="absolute bottom-10 left-1/2 transform -translate-x-1/2 cursor-pointer"
    animate={{
      y: [0, 10, 0],
    }}
    transition={{
      duration: 1.5,
      repeat: Infinity,
      repeatType: "reverse",
    }}
    onClick={() => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })}
  >
    <ChevronDown size={32} color="#ff6600" />
  </motion.div>
);

const ParticleBackground = () => {
  const particles = Array.from({ length: 50 }).map((_, i) => (
    <motion.div
      key={i}
      className="absolute w-2 h-2 bg-orange-500 rounded-full"
      style={{
        top: `${Math.random() * 100}%`,
        left: `${Math.random() * 100}%`,
      }}
      animate={{
        y: [0, Math.random() * 100 - 50],
        x: [0, Math.random() * 100 - 50],
        opacity: [0, 1, 0],
      }}
      transition={{
        duration: Math.random() * 10 + 10,
        repeat: Infinity,
        repeatType: "reverse",
      }}
    />
  ));

  return <div className="absolute inset-0 overflow-hidden">{particles}</div>;
};

export default function Index() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.remove('light');
    document.documentElement.classList.add('dark-bg');
  }, []);

  return (
    <div className="min-h-screen relative overflow-hidden text-white" style={{background: 'linear-gradient(to bottom right, #000000,#000000)'}}>
    <Globes /> 
    <ParticleBackground/>
    <div className="relative z-10"></div>
      
      <Navbar />
      <div className="relative z-10" style={{ 
      marginTop: 'clamp(1rem, 5vw, 10rem)',
      paddingBottom: 'clamp(2rem, 5vw, 4rem)'
    }}>
      <div className="relative" style={{
        minHeight: 'calc(100vh - 80px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}>
        <motion.div
          className="absolute inset-0 flex items-center justify-center"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.5, 0.7, 0.5],
          }}
          transition={{
            duration: 5,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        >
          <div style={{
            width: 'min(24rem, 90vw)',
            height: 'min(24rem, 90vw)',
            background: '#ff6600',
            borderRadius: '50%',
            filter: 'blur(80px)',
            opacity: '0.1'
          }}></div>
        </motion.div>

        <FloatingIcon 
          Icon={Shield} 
          top={5} 
          left={5} 
          size={window.innerWidth < 768 ? 24 : 32} 
          color="#ff6600" 
        />
        <FloatingIcon 
          Icon={Code} 
          top={15} 
          left={85} 
          size={window.innerWidth < 768 ? 24 : 32} 
          color="#ff6600" 
        />
        <FloatingIcon 
          Icon={Lock} 
          top={75} 
          left={15} 
          size={window.innerWidth < 768 ? 24 : 32} 
          color="#ff6600" 
        />
        <FloatingIcon 
          Icon={Globe} 
          top={65} 
          left={85} 
          size={window.innerWidth < 768 ? 24 : 32} 
          color="#ff6600" 
        />

        <main style={{
          maxWidth: '100%',
          margin: '0 auto',
          padding: 'clamp(1rem, 3vw, 2rem)',
          textAlign: 'center',
          position: 'relative'
        }}>
          <motion.h1
            style={{
              fontSize: 'clamp(2rem, 8vw, 3.75rem)',
              fontWeight: 'bold',
              marginBottom: 'clamp(1rem, 3vw, 1.5rem)',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <span className="text-white">We Secure Your</span>
            <CyclingText />
          </motion.h1>
          
          <motion.p
            style={{
              color: '#D1D5DB',
              maxWidth: 'min(42rem, 90vw)',
              margin: '0 auto',
              marginBottom: 'clamp(1.5rem, 5vw, 2.5rem)',
              fontSize: 'clamp(1rem, 4vw, 1.25rem)',
              lineHeight: '1.5'
            }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Empowering your digital presence with state-of-the-art security and unmatched expertise.
          </motion.p>

          <motion.div
  initial={{ opacity: 0, scale: 0.5 }}
  animate={{ opacity: 1, scale: 1 }}
  transition={{ duration: 0.5, delay: 0.4 }}
  style={{
    transform: 'scale(clamp(0.8, 0.8 + 1vw, 1))'
  }}
>
  <GlowingButton className="text-lg hidden md:block">
    Secure Your Future
  </GlowingButton>
</motion.div>

        </main>

        <ScrollDownIndicator />
    </div>
      </div>


      <main>

        <section className="relative md:py-24 py-16">
          <Features classlist="container relative"/>
          <AboutOne />
          <AboutTwo />
          <AmazingFeatures />
          <AboutThree />

         <AchievementsSection/>

          <TestimonialsSection/>
          <Blogs />
          <Faq />


        </section>
      </main>

      <Footer />
    </div>
  );
}
