import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { 
  Shield, 
  Mail, 
  Phone, 
  MapPin, 
  Linkedin,
  Clock,
  ChevronRight,
  Globe,
  Lock,
  Shield as ShieldIcon
} from 'lucide-react';

const FooterSection = ({ title, children }) => (
  <div className="p-6 relative">
    <motion.h4 
      className="text-xl font-bold mb-6 text-white relative inline-block"
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <span className="relative z-10">{title}</span>
      <motion.span 
        className="absolute bottom-0 left-0 h-1 bg-orange-500"
        initial={{ width: 0 }}
        animate={{ width: '100%' }}
        transition={{ duration: 0.5, delay: 0.2 }}
      />
    </motion.h4>
    {children}
  </div>
);

const ContactItem = ({ Icon, text }) => (
  <motion.div 
    className="flex items-center gap-3 mb-4 text-gray-300 hover:text-orange-500 transition-colors duration-300"
    whileHover={{ x: 5 }}
  >
    <Icon className="w-5 h-5 text-orange-500" />
    <span>{text}</span>
  </motion.div>
);

const StatBox = ({ icon: Icon, number, text }) => (
  <motion.div 
    className="flex flex-col items-center p-4 bg-gray-900 rounded-lg"
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <Icon className="w-8 h-8 text-orange-500 mb-2" />
    <span className="text-2xl font-bold text-white mb-1">{number}</span>
    <span className="text-sm text-gray-400">{text}</span>
  </motion.div>
);

export default function Footer() {
  return (
    <footer className="bg-black relative overflow-hidden">
      {/* Background Decorative Elements */}
      <div className="absolute inset-0 opacity-5">
        <div className="absolute top-0 left-0 w-64 h-64 bg-orange-500 rounded-full blur-3xl transform -translate-x-1/2 -translate-y-1/2" />
        <div className="absolute bottom-0 right-0 w-64 h-64 bg-orange-500 rounded-full blur-3xl transform translate-x-1/2 translate-y-1/2" />
      </div>

      <div className="max-w-7xl mx-auto px-4 py-16 relative z-10">
        

        {/* Main Footer Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {/* Company Info */}
          <FooterSection title="About Us">
            <div className="mb-6">
              <div className="flex items-center gap-2 mb-4">
                <span className="text-3xl font-bold text-orange-500">NEX</span>
                <span className="text-3xl font-bold text-white">GEN GUARDS</span>
              </div>
              <p className="text-gray-400 leading-relaxed">
                Leading the way in cybersecurity innovation and digital protection. Your security is our top priority.
              </p>
            </div>
            <div className="flex gap-4">
              <motion.a 
                href="https://linkedin.com"
                className="w-10 h-10 rounded-full bg-gray-800 flex items-center justify-center text-gray-400 hover:bg-orange-500 hover:text-white transition-all duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Linkedin className="w-5 h-5" />
              </motion.a>
              <motion.a 
                href="mailto:contact@nexgenguards.com"
                className="w-10 h-10 rounded-full bg-gray-800 flex items-center justify-center text-gray-400 hover:bg-orange-500 hover:text-white transition-all duration-300"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <Mail className="w-5 h-5" />
              </motion.a>
            </div>
          </FooterSection>

          {/* Quick Links */}
          <FooterSection title="Quick Links">
            <div className="grid grid-cols-1 gap-2">
              {['About Us', 'Services', 'Team', 'Pricing', 'Blog', 'Contact'].map((item) => (
                <motion.a
                  key={item}
                  href={`/${item.toLowerCase()}`}
                  className="flex items-center gap-2 text-gray-300 hover:text-orange-500 transition-colors duration-300"
                  whileHover={{ x: 5 }}
                >
                  <ChevronRight className="w-4 h-4" />
                  <span>{item}</span>
                </motion.a>
              ))}
            </div>
          </FooterSection>

          {/* Contact Info */}
          <FooterSection title="Get in Touch">
            <div className="space-y-4">
              <ContactItem Icon={Mail} text="info@techcompany.com" />
              <ContactItem Icon={Phone} text="+92 3454801844" />
              <ContactItem Icon={MapPin} text="Air University, Business Incubation Center

Islamabad" />
              <ContactItem Icon={Clock} text="24/7 Support Available" />
            </div>
          </FooterSection>

          <FooterSection title="Our Mission">
  <div className="space-y-4">
    <div className="mb-4">
      <h5 className="text-2xl font-bold text-orange-500 mb-2">NexGen Guards</h5>
      <h6 className="text-xl font-semibold text-white mb-3">Using AI for Innovation</h6>
      <p className="text-gray-400 leading-relaxed mb-4">
        NexGen Guards is revolutionizing the way we approach technology, combining AI with top-notch services to create solutions that meet modern demands.
      </p>
    </div>
    <div>
      {[
        
      ].map((service, index) => (
        <motion.div 
          key={index}
          className="flex items-center gap-2 mb-3 text-gray-300 hover:text-orange-500 transition-colors duration-300"
          whileHover={{ x: 5 }}
        >
          <ChevronRight className="w-4 h-4 text-orange-500" />
          <span>{service}</span>
        </motion.div>
      ))}
    </div>
  </div>
</FooterSection>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-800 pt-8 mt-8">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-gray-400 text-center md:text-left">
              © {new Date().getFullYear()} NexGen Guards. All rights reserved.
            </p>
            <div className="flex gap-6 text-gray-400">
              <Link to="/privacy" className="hover:text-orange-500 transition-colors duration-300">Privacy Policy</Link>
              <Link to="/terms" className="hover:text-orange-500 transition-colors duration-300">Terms of Service</Link>
              <Link to="/security" className="hover:text-orange-500 transition-colors duration-300">Security</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}