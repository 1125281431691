import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import client1 from "../assets/images/client/01.jpg";
import client2 from "../assets/images/client/02.jpg";
import client3 from "../assets/images/client/03.jpg";
import blog1 from "../assets/images/blog/1.jpg";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
// import Switcher from "../components/switcher";

import { blogData } from "../data/data";

import { FiHeart, FiMessageCircle, FiUser, FiMail } from "../assets/icons/vander";

export default function BlogDetails() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("dark");
    document.documentElement.classList.remove("light");
  }, []);

  const params = useParams();
  const id = params.id;
  const data = blogData.find((blog) => blog.id === parseInt(id));

  return (
    <div className="dark-bg" style={{ color: "white" }}>
      <>
        <Navbar />
        <section className="relative md:pt-44 pt-36 bg-gradient-to-b from-[#ff6600]/20 dark:from-[#ff6600]/40 to-transparent">
          <div className="container relative">
            <div className="md:flex justify-center">
              <div className="lg:w-2/3 md:w-4/5">
                <Link to="" className="bg-[#ff6600] text-white text-[12px] font-semibold px-2.5 py-0.5 rounded h-5">
                  AI
                </Link>
                <h5
                  className="md:text-4xl text-3xl font-bold md:tracking-normal tracking-normal md:leading-normal leading-normal mt-3"
                  style={{ color: "#ff6600" }}
                >
                  {data?.title
                    ? data?.title
                    : "What is Artificial Intelligence and How Does AI Work For Human"}
                </h5>
                <p className="mt-3" style={{ color: "white" }}>
                  Hello there! I am ChatGPT, a language model developed by OpenAI, based on the powerful GPT (Generative Pre-trained Transformer) architecture.
                </p>

                <div className="flex items-center mt-5">
                  <img
                    src={data?.client ? data?.client : client1}
                    className="h-12 w-12 rounded-full"
                    alt=""
                  />

                  <div className="ms-2">
                    <h6>
                      <Link to="" className="font-medium" style={{ color: "#ff6600" }}>
                        {data?.author ? data?.author : "Calvin Carlo"}
                      </Link>
                      <Link to="" className="ms-1 font-medium" style={{ color: "#ff6600" }}>
                        <i className="mdi mdi-circle-medium"></i>Follow
                      </Link>
                    </h6>
                    <span className="text-sm" style={{ color: "white" }}>
                      {data?.date ? data?.date : "August 25, 2023"} <span><i className="mdi mdi-circle-medium"></i>6 min read</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative md:pb-24 pb-16 pt-7">
          <div className="container relative">
            <div className="md:flex justify-center">
              <div className="lg:w-2/3 md:w-4/5">
                <img
                  src={data?.image ? data?.image : blog1}
                  className="rounded-md"
                  alt=""
                />

                <p className="mt-4" style={{ color: "white" }}>
                  Artificial Intelligence (AI) is a groundbreaking field of computer science that aims to create intelligent machines capable of performing tasks that typically require human intelligence.
                </p>

                <p className="mt-4" style={{ color: "white" }}>
                  By simulating human cognitive processes, AI systems can analyze vast amounts of data, identify patterns, make predictions, and learn from experiences, enabling them to continuously improve their performance.
                </p>

                <div
                  className="relative rounded-md border-s-4 px-6 py-10 mt-4"
                  style={{ borderColor: "#ff6600", color: "white" }}
                >
                  <p className="text-2xl font-medium" style={{ color: "#ff6600" }}>
                    “ It's still not confirmed whether Google's algorithm supports AI-generated content or not “
                  </p>
                  <p className="mt-4 text-end" style={{ color: "white" }}>
                    - Mortal.Ai
                  </p>
                  <div
                    className="absolute text-8xl -top-0 start-4 -z-1"
                    style={{ color: "#ff6600", opacity: 0.1 }}
                  >
                    <i className="mdi mdi-format-quote-open"></i>
                  </div>
                </div>

                <p className="mt-4" style={{ color: "white" }}>
                  On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue.
                </p>

                <div className="flex justify-between py-4 border-y mt-5" style={{ borderColor: "#ff6600", color: "white" }}>
                  <ul className="list-none">
                    <li className="inline">
                      <Link to="#" className="inline-flex items-center" style={{ color: "white" }}>
                        <FiHeart className="h-4 w-4" style={{ color: "white" }} /> 233
                      </Link>
                    </li>
                    <li className="inline ms-2">
                      <Link to="#" className="inline-flex items-center" style={{ color: "white" }}>
                        <FiMessageCircle className="h-4 w-4" style={{ color: "white" }} /> 08
                      </Link>
                    </li>
                  </ul>

                  <ul className="list-none">
                    <li className="inline">
                      <Link to="#" className="inline-flex items-center" style={{ color: "white" }}>
                        <i data-feather="share-2" className="h-4 w-4" style={{ color: "white" }}></i>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="mt-6">
                  <h5 className="text-xl font-semibold" style={{ color: "#ff6600" }}>Comments:</h5>

                  {/* Comment Section */}
                  <div className="mt-8">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <img
                          src={client2}
                          className="h-11 w-11 rounded-full shadow"
                          alt=""
                        />

                        <div className="ms-3 flex-1">
                          <Link to="" className="font-semibold" style={{ color: "#ff6600" }}>
                            Calvin Carlo
                          </Link>
                          <p className="text-sm" style={{ color: "white" }}>
                            August 26, 2023 at 01:25 pm
                          </p>
                        </div>
                      </div>

                      <Link to="" className="ms-5" style={{ color: "#ff6600" }}>
                        <i className="mdi mdi-reply"></i> Reply
                      </Link>
                    </div>
                    <div className="p-4 rounded-md shadow mt-6" style={{ backgroundColor: "#2c2c2c", color: "white" }}>
                      <p className="italic">
                        " There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "
                      </p>
                    </div>
                  </div>

                  <div className="mt-8">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <img
                          src={client3}
                          className="h-11 w-11 rounded-full shadow"
                          alt=""
                        />

                        <div className="ms-3 flex-1">
                          <Link to="" className="font-semibold" style={{ color: "#ff6600" }}>
                            Calvin Carlo
                          </Link>
                          <p className="text-sm" style={{ color: "white" }}>
                            August 26, 2023 at 01:25 pm
                          </p>
                        </div>
                      </div>

                      <Link to="" className="ms-5" style={{ color: "#ff6600" }}>
                        <i className="mdi mdi-reply"></i> Reply
                      </Link>
                    </div>
                    <div className="p-4 rounded-md shadow mt-6" style={{ backgroundColor: "#2c2c2c", color: "white" }}>
                      <p className="italic">
                        " There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour "
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  <h5 className="text-xl font-semibold" style={{ color: "#ff6600" }}>Leave A Comment:</h5>

                  <form className="mt-8">
                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-6 mb-5">
                        <div className="text-start">
                          <label htmlFor="name" className="font-semibold" style={{ color: "#ff6600" }}>
                            Your Name:
                          </label>
                          <div className="form-icon relative mt-2">
                            <FiUser className="w-4 h-4 text-white absolute top-3 start-4" />
                            <input id="name" type="text" className="form-input ps-11 border border-gray-300 dark:border-gray-700 dark:bg-slate-800" placeholder="Name :" style={{ color: "white" }} />
                          </div>
                        </div>
                      </div>

                      <div className="lg:col-span-6 mb-5">
                        <div className="text-start">
                          <label htmlFor="email" className="font-semibold" style={{ color: "#ff6600" }}>
                            Your Email:
                          </label>
                          <div className="form-icon relative mt-2">
                            <FiMail className="w-4 h-4 text-white absolute top-3 start-4" />
                            <input id="email" type="email" className="form-input ps-11 border border-gray-300 dark:border-gray-700 dark:bg-slate-800" placeholder="Email :" style={{ color: "white" }} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-1">
                      <div className="text-start">
                        <label htmlFor="comments" className="font-semibold" style={{ color: "#ff6600" }}>
                          Your Comment:
                        </label>
                        <div className="relative mt-2">
                          <textarea id="comments" className="form-input border border-gray-300 dark:border-gray-700 dark:bg-slate-800" placeholder="Message :" style={{ color: "white" }}></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="mt-6">
                      <button type="submit" className="btn bg-[#ff6600] border-[#ff6600] text-white rounded-md">
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </>
    </div>
  );
}
