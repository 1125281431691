import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function AmazingFeatures() {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const featureData = [
        {
            icon: "mdi mdi-flip-horizontal",
            title: 'Web-Development',
            desc: ' Our advanced web development solutions deliver cutting-edge, responsive, and user-friendly websites tailored to your business needs, safeguarding your online presence and reputation..'
        },
        {
            icon: "mdi mdi-email-edit-outline",
            title: 'Content Generator',
            desc: 'Generate high-quality, relevant content with AI-driven tools tailored to your industry. From blogs to social media posts, our content generator helps you stay ahead in digital storytelling.'
        },
        {
            icon: "mdi mdi-star-outline",
            title: 'Search Engine Optimization',
            desc: 'Enhance your online visibility and drive organic traffic with our comprehensive SEO services. We optimize content, structure, and keywords to rank your site higher on search engines.'
        },
        {
            icon: "mdi mdi-bookmark-outline",
            title: 'Digital name generator',
            desc: 'Create a unique digital identity with our AI-powered name generator, crafted to resonate with your target audience. Perfect for new brands, products, and initiatives.'
        },
        {
            icon: "mdi mdi-account-check-outline",
            title: 'Ad Targeting tips',
            desc: 'Maximize your ROI with data-driven ad targeting strategies. We analyze audience insights and optimize your campaigns to reach the right customers at the right time.'
        },
        {
            icon: "mdi mdi-comment-outline",
            title: 'Content Rewriter',
            desc: 'Refresh existing content to keep it relevant and engaging. Our AI-powered content rewriter improves readability, boosts SEO, and aligns with current trends and best practices.'
        },
    ];

    return (
        <>
            <style>
                {`
                    .text-darkOrange {
                        color: #ff6600 !important;
                    }
                    .bg-card {
                        background-color: #1F1F1F;
                    }
                    .feature-card {
                        opacity: 0;
                        transform: translateY(50px) rotate(5deg);
                        animation: fadeInUp 0.6s ease-out forwards;
                        transition: all 0.3s ease;
                    }
                    .feature-card:nth-child(odd) {
                        animation-name: fadeInLeft;
                    }
                    .feature-card:nth-child(even) {
                        animation-name: fadeInRight;
                    }
                    @keyframes fadeInUp {
                        to {
                            opacity: 1;
                            transform: translateY(0) rotate(0);
                        }
                    }
                    @keyframes fadeInLeft {
                        from {
                            opacity: 0;
                            transform: translateX(-50px) rotate(-5deg);
                        }
                        to {
                            opacity: 1;
                            transform: translateX(0) rotate(0);
                        }
                    }
                    @keyframes fadeInRight {
                        from {
                            opacity: 0;
                            transform: translateX(50px) rotate(5deg);
                        }
                        to {
                            opacity: 1;
                            transform: translateX(0) rotate(0);
                        }
                    }
                    .feature-icon {
                        transition: all 0.5s ease;
                    }
                    .feature-card:hover .feature-icon {
                        transform: scale(1.2) rotate(360deg);
                    }
                    .feature-card:hover {
                        transform: translateY(-10px);
                        box-shadow: 0 10px 20px rgba(0,0,0,0.2);
                    }
                    .pulse {
                        animation: pulse 2s infinite;
                    }
                    @keyframes pulse {
                        0% {
                            transform: scale(1);
                        }
                        50% {
                            transform: scale(1.05);
                        }
                        100% {
                            transform: scale(1);
                        }
                    }
                    .feature-title {
                        position: relative;
                        display: inline-block;
                    }
                    .feature-title::after {
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                        background-color: #ff6600;
                        transition: width 0.3s ease;
                    }
                    .feature-card:hover .feature-title::after {
                        width: 100%;
                    }
                    .read-more {
                        position: relative;
                        overflow: hidden;
                    }
                    .read-more::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: -100%;
                        width: 100%;
                        height: 2px;
                        background-color: #ff6600;
                        transition: left 0.3s ease;
                    }
                    .feature-card:hover .read-more::before {
                        left: 0;
                    }
                `}
            </style>
            <div className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-darkOrange pulse">Amazing Features</h3>
                    <p className="text-slate-400 max-w-xl mx-auto">Artificial intelligence makes it fast easy to create content for your blog, social media, website, and more!</p>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                    {featureData.map((item, index) => {
                        return (
                            <div 
                                className="feature-card px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-lg bg-card" 
                                key={index} 
                                style={{ animationDelay: `${index * 0.1}s` }}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                            >
                                <i className={`${item.icon} text-4xl text-darkOrange bg-gradient-to-tl to-amber-400 from-fuchsia-600 text-transparent bg-clip-text feature-icon`}></i>

                                <div className="content mt-7">
                                    <Link to="" className="feature-title text-lg font-medium text-darkOrange hover:text-amber-400 duration-500">{item.title}</Link>
                                    <p className="text-slate-400 mt-3">{item.desc}</p>

                                    
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    );
}