import React, { useState, useEffect } from 'react';
import AdvisorSection from '../components/advisorCard';
import Teams from '../components/teams';

const TeamsPage = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-[#3E2723] text-white overflow-hidden">
      {/* Enhanced Header Section */}
      <div className="relative h-screen flex items-center justify-center overflow-hidden">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 400" className="absolute inset-0 w-full h-full">
          <defs>
            <linearGradient id="headerGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" style={{stopColor: '#3E2723', stopOpacity: 1}} />
              <stop offset="100%" style={{stopColor: '#4E342E', stopOpacity: 1}} />
            </linearGradient>
            <filter id="noise" x="0%" y="0%" width="100%" height="100%">
              <feTurbulence type="fractalNoise" baseFrequency="0.65" numOctaves="3" stitchTiles="stitch"/>
              <feComponentTransfer>
                <feFuncR type="linear" slope="2" intercept="-0.5"/>
                <feFuncG type="linear" slope="2" intercept="-0.5"/>
                <feFuncB type="linear" slope="2" intercept="-0.5"/>
              </feComponentTransfer>
              <feComposite operator="in" in2="SourceGraphic"/>
            </filter>
          </defs>
          
          {/* Background */}
          <rect width="100%" height="100%" fill="url(#headerGradient)"/>
          <rect width="100%" height="100%" fill="#D7CCC8" opacity="0.05" filter="url(#noise)"/>
          
          {/* Decorative elements */}
          <circle cx="50" cy="50" r="100" fill="#FF5722" opacity="0.1"/>
          <circle cx="750" cy="350" r="150" fill="#FF9800" opacity="0.1"/>
          <path d="M0,400 Q400,300 800,400" stroke="#FFAB91" strokeWidth="2" fill="none" opacity="0.3"/>
          <path d="M0,380 Q400,280 800,380" stroke="#FFE0B2" strokeWidth="2" fill="none" opacity="0.2"/>
          
          {/* Main text */}
          <text x="400" y="180" fontFamily="Arial, sans-serif" fontSize="48" fontWeight="bold" textAnchor="middle" fill="#FFFFFF">Our Exceptional Team</text>
          
          {/* Subtitle */}
          <text x="400" y="230" fontFamily="Arial, sans-serif" fontSize="24" textAnchor="middle" fill="#FFB74D">Innovating Together</text>
          
          {/* Stylized team icons */}
          <g transform="translate(300,280)">
            <circle cx="0" cy="0" r="25" fill="#FF5722"/>
            <circle cx="50" cy="0" r="25" fill="#FF9800"/>
            <circle cx="100" cy="0" r="25" fill="#FFA726"/>
            <circle cx="150" cy="0" r="25" fill="#FFB74D"/>
            <circle cx="200" cy="0" r="25" fill="#FFCC80"/>
          </g>
          
          {/* Down arrow */}
          <path d="M390,350 L400,370 L410,350 Z" fill="#FFFFFF">
            <animateTransform 
              attributeName="transform"
              attributeType="XML"
              type="translate"
              values="0 0; 0 10; 0 0"
              dur="2s"
              repeatCount="indefinite"/>
          </path>
        </svg>
      </div>

      {/* Main Content */}
      <div className="relative z-10">
        {/* Teams Section */}
        <section className="py-20 bg-gradient-to-b from-[#3E2723] to-[#4E342E]">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl md:text-5xl font-bold text-center mb-12 text-[#FF9800]">Meet Our Team</h2>
            <Teams />
          </div>
        </section>

        {/* Parallax Divider */}
        <div className="relative h-64 overflow-hidden">
          <div 
            className="absolute inset-0 bg-cover bg-center"
            style={{
              backgroundImage: "url('/path-to-your-image.jpg')",
              transform: `translateY(${scrollPosition * 0.5}px)`
            }}
          />
          <div className="absolute inset-0 bg-[#3E2723] opacity-50" />
          <div className="absolute inset-0 flex items-center justify-center">
            <h3 className="text-3xl md:text-4xl font-bold text-white text-center">Driving Innovation Forward</h3>
          </div>
        </div>

        {/* Advisor Section */}
        <section className="py-20 bg-gradient-to-t from-[#3E2723] to-[#4E342E]">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl md:text-5xl font-bold text-center mb-12 text-[#FF9800]">Our Advisors</h2>
            <AdvisorSection />
          </div>
        </section>
      </div>

      {/* Footer */}
      <footer className="bg-[#3E2723] text-white py-8">
        <div className="container mx-auto text-center">
          <p className="text-[#FF9800] font-semibold">© 2024 Our Exceptional Team. All rights reserved.</p>
        </div>
      </footer>

      {/* Global Styles */}
      <style jsx global>{`
        body {
          background-color: #3E2723;
        }
        
        @keyframes fade-in-up {
          from { 
            opacity: 0;
            transform: translateY(20px);
          }
          to { 
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-fade-in-up { 
          animation: fade-in-up 1s ease-out forwards; 
        }
      `}</style>
    </div>
  );
};

export default TeamsPage;