import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Star, ArrowLeft, ArrowRight, Quote } from 'lucide-react';

const testimonials = [
  {
    id: 1,
    name: "Sarah Johnson",
    content: "The cybersecurity solutions provided have transformed our digital infrastructure. The level of protection and peace of mind we now have is extraordinary.",
    rating: 5
  },
  {
    id: 2,
    name: "Michael Chen",
    content: "Outstanding web development services! The team delivered a website that exceeded our expectations in both functionality and security.",
    rating: 5
  },
  {
    id: 3,
    name: "Emma Davis",
    content: "Their AI integration solutions have revolutionized our operations. The efficiency gains and security improvements are remarkable.",
    rating: 5
  }
];

const TestimonialsSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  useEffect(() => {
    if (isAutoPlaying) {
      const interval = setInterval(() => {
        setDirection(1);
        setCurrentIndex((prev) => (prev + 1) % testimonials.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [isAutoPlaying]);

  const slideVariants = {
    enter: (direction) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    })
  };

  const navigate = (newDirection) => {
    setDirection(newDirection);
    setCurrentIndex((prev) => (newDirection > 0 ? 
      (prev + 1) % testimonials.length : 
      (prev - 1 + testimonials.length) % testimonials.length
    ));
    setIsAutoPlaying(false);
  };

  return (
    <div className="relative py-16 bg-black">
      <div className="absolute inset-0 overflow-hidden">
        <motion.div
          className="absolute inset-0"
          animate={{
            background: [
              'radial-gradient(circle at 30% 30%, rgba(255, 102, 0, 0.1) 0%, transparent 70%)',
              'radial-gradient(circle at 70% 70%, rgba(255, 102, 0, 0.1) 0%, transparent 70%)'
            ]
          }}
          transition={{ duration: 5, repeat: Infinity, repeatType: "reverse" }}
        />
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="text-center mb-12">
          <motion.h2 
            className="text-4xl font-bold mb-4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            What Our Clients Say
          </motion.h2>
          <motion.div 
            className="w-20 h-1 bg-orange-500 mx-auto"
            initial={{ width: 0 }}
            whileInView={{ width: 80 }}
            transition={{ duration: 0.8 }}
          />
        </div>

        <div className="relative h-[400px] max-w-4xl mx-auto">
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={currentIndex}
              custom={direction}
              variants={slideVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 }
              }}
              className="absolute w-full"
            >
              <div className="bg-gray-900 rounded-2xl p-8 shadow-2xl relative overflow-hidden">
                <motion.div
                  className="absolute top-4 right-4 text-orange-500 opacity-10"
                  initial={{ rotate: -20, scale: 0.8 }}
                  animate={{ rotate: 0, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Quote size={80} />
                </motion.div>

                <div className="flex flex-col items-center text-center">
                  <motion.div
                    className="w-20 h-20 rounded-full border-4 border-orange-500 mb-4 flex items-center justify-center bg-gray-800"
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <span className="text-2xl font-bold text-white">
                      {testimonials[currentIndex].name.charAt(0)}
                    </span>
                  </motion.div>
                  
                  <motion.div 
                    className="flex gap-1 mb-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.3 }}
                  >
                    {[...Array(testimonials[currentIndex].rating)].map((_, i) => (
                      <Star key={i} className="fill-orange-500 text-orange-500" size={20} />
                    ))}
                  </motion.div>

                  <motion.p 
                    className="text-lg text-gray-300 mb-6"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                  >
                    "{testimonials[currentIndex].content}"
                  </motion.p>

                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 }}
                  >
                    <h4 className="text-xl font-bold text-white">
                      {testimonials[currentIndex].name}
                    </h4>
                    <p className="text-orange-500">
                      {testimonials[currentIndex].role}
                    </p>
                  </motion.div>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>

          <div className="absolute left-0 right-0 bottom-[-70px] flex justify-center gap-4">
            <motion.button
              className="p-2 rounded-full bg-gray-800 hover:bg-orange-500 transition-colors"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => navigate(-1)}
            >
              <ArrowLeft className="text-white" size={24} />
            </motion.button>
            <motion.button
              className="p-2 rounded-full bg-gray-800 hover:bg-orange-500 transition-colors"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => navigate(1)}
            >
              <ArrowRight className="text-white" size={24} />
            </motion.button>
          </div>
        </div>

        <div className="flex justify-center mt-20 gap-2">
          {testimonials.map((_, index) => (
            <motion.button
              key={index}
              className={`w-3 h-3 rounded-full ${
                index === currentIndex ? 'bg-orange-500' : 'bg-gray-600'
              }`}
              whileHover={{ scale: 1.2 }}
              onClick={() => {
                setDirection(index > currentIndex ? 1 : -1);
                setCurrentIndex(index);
                setIsAutoPlaying(false);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;